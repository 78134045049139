import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './schedule1.css'

const Schedule1 = (props) => {
  return (
    <div className="schedule1-container100">
      <Helmet>
        <title>Schedule1 - Crips Cleaning</title>
        <meta
          name="description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
        <meta property="og:title" content="Schedule1 - Crips Cleaning" />
        <meta
          property="og:description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
      </Helmet>
      <div className="schedule1-container101">
        <img
          alt="image"
          src={require("./img/logo-200h.png")}
          className="schedule1-image10"
        />
        <div className="schedule1-container102">
          <span className="schedule1-text100">OVERVIEW</span>
          <Link to="/dashboard" className="schedule1-navlink1">
            <div className="schedule1-container103">
              <img
                alt="image"
                src={require("./img/homep-200h.png")}
                className="schedule1-image11"
              />
              <span className="schedule1-text101">Dashboard</span>
            </div>
          </Link>
          <div className="schedule1-container104">
            <img
              alt="image"
              src={require("./img/calenderx-200h.png")}
              className="schedule1-image12"
            />
            <span className="schedule1-text102">Schedule</span>
          </div>
          <Link to="/referral" className="schedule1-navlink2">
            <div className="schedule1-container105">
              <img
                alt="image"
                src={require("./img/link-200h.png")}
                className="schedule1-image13"
              />
              <span className="schedule1-text103">Referrals</span>
            </div>
          </Link>
          <Link to="/reward" className="schedule1-navlink3">
            <div className="schedule1-container106">
              <img
                alt="image"
                src={require("./img/lock1-200h.png")}
                className="schedule1-image14"
              />
              <span className="schedule1-text104">Rewards</span>
            </div>
          </Link>
          <Link to="/cleanerspass" className="schedule1-navlink4">
            <div className="schedule1-container107">
              <img
                alt="image"
                src={require("./img/key-200h.png")}
                className="schedule1-image15"
              />
              <span className="schedule1-text105">CleanPass</span>
            </div>
          </Link>
        </div>
        <div className="schedule1-container108">
          <span className="schedule1-text106">SETTINGS</span>
          <Link to="/settings" className="schedule1-navlink5">
            <div className="schedule1-container109">
              <img
                alt="image"
                src={require("./img/settings_x-200h.png")}
                className="schedule1-image16"
              />
              <span className="schedule1-text107">Settings</span>
            </div>
          </Link>
          <div className="schedule1-container110">
            <img
              alt="image"
              src={require("./img/exitx-200h.png")}
              className="schedule1-image17"
            />
            <span className="schedule1-text108">Logout</span>
          </div>
        </div>
      </div>
      <div className="schedule1-container111">
        <div className="schedule1-container112">
          <span className="schedule1-text109">Schedule</span>
          <img
            alt="image"
            src={require("./img/question-200h.png")}
            className="schedule1-image18"
          />
          <div className="schedule1-container113">
            <img
              alt="image"
              src={require("./img/search-200h.png")}
              className="schedule1-image19"
            />
            <span className="schedule1-text110">Search for anything...</span>
            <input type="text" className="schedule1-textinput input" />
          </div>
          <div className="schedule1-container114">
            <span className="schedule1-text111">Book Now</span>
          </div>
        </div>
        <div className="schedule1-container115">
          <div className="schedule1-container116">
            <div className="schedule1-container117">
              <div className="schedule1-container118">
                <Link to="/schedule" className="schedule1-navlink6">
                  Monthly
                </Link>
                <span className="schedule1-text112">Fortnightly</span>
              </div>
              <div className="schedule1-container119">
                <span className="schedule1-text113">January 2024</span>
                <div className="schedule1-container120">
                  <div className="schedule1-container121">
                    <img
                      alt="image"
                      src={require("./img/left-200w.png")}
                      className="schedule1-image20"
                    />
                  </div>
                  <div className="schedule1-container122">
                    <img
                      alt="image"
                      src={require("./img/right-200w.png")}
                      className="schedule1-image21"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="schedule1-container123">
              <div className="schedule1-container124">
                <span className="schedule1-text114">MON</span>
              </div>
              <div className="schedule1-container125">
                <span className="schedule1-text115">TUE</span>
              </div>
              <div className="schedule1-container126">
                <span className="schedule1-text116">WED</span>
              </div>
              <div className="schedule1-container127">
                <span className="schedule1-text117">THU</span>
              </div>
              <div className="schedule1-container128">
                <span className="schedule1-text118">FRI</span>
              </div>
              <div className="schedule1-container129">
                <span className="schedule1-text119">SAT</span>
              </div>
              <div className="schedule1-container130">
                <span className="schedule1-text120">SUN</span>
              </div>
            </div>
            <div className="schedule1-container131">
              <div className="schedule1-container132">
                <div className="schedule1-container133">
                  <span className="schedule1-text121">1</span>
                  <div className="schedule1-container134">
                    <span className="schedule1-text122">1</span>
                  </div>
                  <div className="schedule1-container135">
                    <span className="schedule1-text123">1</span>
                  </div>
                  <div className="schedule1-container136">
                    <span className="schedule1-text124">1</span>
                  </div>
                </div>
                <div className="schedule1-container137">
                  <span className="schedule1-text125">Deep Clean</span>
                  <div className="schedule1-container138"></div>
                </div>
                <div className="schedule1-container139">
                  <span className="schedule1-text126">Regular Clean</span>
                  <div className="schedule1-container140"></div>
                </div>
                <div className="schedule1-container141">
                  <span className="schedule1-text127">Vacate Clean</span>
                  <div className="schedule1-container142"></div>
                </div>
                <div className="schedule1-container143">
                  <span className="schedule1-text128">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container144">
                <div className="schedule1-container145">
                  <span className="schedule1-text129">2</span>
                  <div className="schedule1-container146">
                    <span className="schedule1-text130">1</span>
                  </div>
                  <div className="schedule1-container147">
                    <span className="schedule1-text131">1</span>
                  </div>
                  <div className="schedule1-container148">
                    <span className="schedule1-text132">1</span>
                  </div>
                </div>
                <div className="schedule1-container149">
                  <span className="schedule1-text133">Deep Clean</span>
                  <div className="schedule1-container150"></div>
                </div>
                <div className="schedule1-container151">
                  <span className="schedule1-text134">Regular Clean</span>
                  <div className="schedule1-container152"></div>
                </div>
                <div className="schedule1-container153">
                  <span className="schedule1-text135">Vacate Clean</span>
                  <div className="schedule1-container154"></div>
                </div>
                <div className="schedule1-container155">
                  <span className="schedule1-text136">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container156">
                <div className="schedule1-container157">
                  <span className="schedule1-text137">3</span>
                  <div className="schedule1-container158">
                    <span className="schedule1-text138">1</span>
                  </div>
                  <div className="schedule1-container159">
                    <span className="schedule1-text139">1</span>
                  </div>
                  <div className="schedule1-container160">
                    <span className="schedule1-text140">1</span>
                  </div>
                </div>
                <div className="schedule1-container161">
                  <span className="schedule1-text141">Deep Clean</span>
                  <div className="schedule1-container162"></div>
                </div>
                <div className="schedule1-container163">
                  <span className="schedule1-text142">Regular Clean</span>
                  <div className="schedule1-container164"></div>
                </div>
                <div className="schedule1-container165">
                  <span className="schedule1-text143">Vacate Clean</span>
                  <div className="schedule1-container166"></div>
                </div>
                <div className="schedule1-container167">
                  <span className="schedule1-text144">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container168">
                <div className="schedule1-container169">
                  <span className="schedule1-text145">4</span>
                  <div className="schedule1-container170">
                    <span className="schedule1-text146">1</span>
                  </div>
                  <div className="schedule1-container171">
                    <span className="schedule1-text147">1</span>
                  </div>
                  <div className="schedule1-container172">
                    <span className="schedule1-text148">1</span>
                  </div>
                </div>
                <div className="schedule1-container173">
                  <span className="schedule1-text149">Deep Clean</span>
                  <div className="schedule1-container174"></div>
                </div>
                <div className="schedule1-container175">
                  <span className="schedule1-text150">Regular Clean</span>
                  <div className="schedule1-container176"></div>
                </div>
                <div className="schedule1-container177">
                  <span className="schedule1-text151">Vacate Clean</span>
                  <div className="schedule1-container178"></div>
                </div>
                <div className="schedule1-container179">
                  <span className="schedule1-text152">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container180">
                <div className="schedule1-container181">
                  <span className="schedule1-text153">5</span>
                  <div className="schedule1-container182">
                    <span className="schedule1-text154">1</span>
                  </div>
                  <div className="schedule1-container183">
                    <span className="schedule1-text155">1</span>
                  </div>
                  <div className="schedule1-container184">
                    <span className="schedule1-text156">1</span>
                  </div>
                </div>
                <div className="schedule1-container185">
                  <span className="schedule1-text157">Deep Clean</span>
                  <div className="schedule1-container186"></div>
                </div>
                <div className="schedule1-container187">
                  <span className="schedule1-text158">Regular Clean</span>
                  <div className="schedule1-container188"></div>
                </div>
                <div className="schedule1-container189">
                  <span className="schedule1-text159">Vacate Clean</span>
                  <div className="schedule1-container190"></div>
                </div>
                <div className="schedule1-container191">
                  <span className="schedule1-text160">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container192">
                <div className="schedule1-container193">
                  <span className="schedule1-text161">6</span>
                  <div className="schedule1-container194">
                    <span className="schedule1-text162">1</span>
                  </div>
                  <div className="schedule1-container195">
                    <span className="schedule1-text163">1</span>
                  </div>
                  <div className="schedule1-container196">
                    <span className="schedule1-text164">1</span>
                  </div>
                </div>
                <div className="schedule1-container197">
                  <span className="schedule1-text165">Deep Clean</span>
                  <div className="schedule1-container198"></div>
                </div>
                <div className="schedule1-container199">
                  <span className="schedule1-text166">Regular Clean</span>
                  <div className="schedule1-container200"></div>
                </div>
                <div className="schedule1-container201">
                  <span className="schedule1-text167">Vacate Clean</span>
                  <div className="schedule1-container202"></div>
                </div>
                <div className="schedule1-container203">
                  <span className="schedule1-text168">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container204">
                <div className="schedule1-container205">
                  <span className="schedule1-text169">7</span>
                  <div className="schedule1-container206">
                    <span className="schedule1-text170">1</span>
                  </div>
                  <div className="schedule1-container207">
                    <span className="schedule1-text171">1</span>
                  </div>
                  <div className="schedule1-container208">
                    <span className="schedule1-text172">1</span>
                  </div>
                </div>
                <div className="schedule1-container209">
                  <span className="schedule1-text173">Deep Clean</span>
                  <div className="schedule1-container210"></div>
                </div>
                <div className="schedule1-container211">
                  <span className="schedule1-text174">Regular Clean</span>
                  <div className="schedule1-container212"></div>
                </div>
                <div className="schedule1-container213">
                  <span className="schedule1-text175">Vacate Clean</span>
                  <div className="schedule1-container214"></div>
                </div>
                <div className="schedule1-container215">
                  <span className="schedule1-text176">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule1-container216">
              <div className="schedule1-container217">
                <div className="schedule1-container218">
                  <span className="schedule1-text177">8</span>
                  <div className="schedule1-container219">
                    <span className="schedule1-text178">1</span>
                  </div>
                  <div className="schedule1-container220">
                    <span className="schedule1-text179">1</span>
                  </div>
                  <div className="schedule1-container221">
                    <span className="schedule1-text180">1</span>
                  </div>
                </div>
                <div className="schedule1-container222">
                  <span className="schedule1-text181">Deep Clean</span>
                  <div className="schedule1-container223"></div>
                </div>
                <div className="schedule1-container224">
                  <span className="schedule1-text182">Regular Clean</span>
                  <div className="schedule1-container225"></div>
                </div>
                <div className="schedule1-container226">
                  <span className="schedule1-text183">Vacate Clean</span>
                  <div className="schedule1-container227"></div>
                </div>
                <div className="schedule1-container228">
                  <span className="schedule1-text184">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container229">
                <div className="schedule1-container230">
                  <span className="schedule1-text185">9</span>
                  <div className="schedule1-container231">
                    <span className="schedule1-text186">1</span>
                  </div>
                  <div className="schedule1-container232">
                    <span className="schedule1-text187">1</span>
                  </div>
                  <div className="schedule1-container233">
                    <span className="schedule1-text188">1</span>
                  </div>
                </div>
                <div className="schedule1-container234">
                  <span className="schedule1-text189">Deep Clean</span>
                  <div className="schedule1-container235"></div>
                </div>
                <div className="schedule1-container236">
                  <span className="schedule1-text190">Regular Clean</span>
                  <div className="schedule1-container237"></div>
                </div>
                <div className="schedule1-container238">
                  <span className="schedule1-text191">Vacate Clean</span>
                  <div className="schedule1-container239"></div>
                </div>
                <div className="schedule1-container240">
                  <span className="schedule1-text192">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container241">
                <div className="schedule1-container242">
                  <span className="schedule1-text193">10</span>
                  <div className="schedule1-container243">
                    <span className="schedule1-text194">1</span>
                  </div>
                  <div className="schedule1-container244">
                    <span className="schedule1-text195">1</span>
                  </div>
                  <div className="schedule1-container245">
                    <span className="schedule1-text196">1</span>
                  </div>
                </div>
                <div className="schedule1-container246">
                  <span className="schedule1-text197">Deep Clean</span>
                  <div className="schedule1-container247"></div>
                </div>
                <div className="schedule1-container248">
                  <span className="schedule1-text198">Regular Clean</span>
                  <div className="schedule1-container249"></div>
                </div>
                <div className="schedule1-container250">
                  <span className="schedule1-text199">Vacate Clean</span>
                  <div className="schedule1-container251"></div>
                </div>
                <div className="schedule1-container252">
                  <span className="schedule1-text200">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container253">
                <div className="schedule1-container254">
                  <span className="schedule1-text201">11</span>
                  <div className="schedule1-container255">
                    <span className="schedule1-text202">1</span>
                  </div>
                  <div className="schedule1-container256">
                    <span className="schedule1-text203">1</span>
                  </div>
                  <div className="schedule1-container257">
                    <span className="schedule1-text204">1</span>
                  </div>
                </div>
                <div className="schedule1-container258">
                  <span className="schedule1-text205">Deep Clean</span>
                  <div className="schedule1-container259"></div>
                </div>
                <div className="schedule1-container260">
                  <span className="schedule1-text206">Regular Clean</span>
                  <div className="schedule1-container261"></div>
                </div>
                <div className="schedule1-container262">
                  <span className="schedule1-text207">Vacate Clean</span>
                  <div className="schedule1-container263"></div>
                </div>
                <div className="schedule1-container264">
                  <span className="schedule1-text208">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container265">
                <div className="schedule1-container266">
                  <span className="schedule1-text209">12</span>
                  <div className="schedule1-container267">
                    <span className="schedule1-text210">1</span>
                  </div>
                  <div className="schedule1-container268">
                    <span className="schedule1-text211">1</span>
                  </div>
                  <div className="schedule1-container269">
                    <span className="schedule1-text212">12</span>
                  </div>
                </div>
                <div className="schedule1-container270">
                  <span className="schedule1-text213">Deep Clean</span>
                  <div className="schedule1-container271"></div>
                </div>
                <div className="schedule1-container272">
                  <span className="schedule1-text214">Regular Clean</span>
                  <div className="schedule1-container273"></div>
                </div>
                <div className="schedule1-container274">
                  <span className="schedule1-text215">Vacate Clean</span>
                  <div className="schedule1-container275"></div>
                </div>
                <div className="schedule1-container276">
                  <span className="schedule1-text216">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container277">
                <div className="schedule1-container278">
                  <span className="schedule1-text217">13</span>
                  <div className="schedule1-container279">
                    <span className="schedule1-text218">1</span>
                  </div>
                  <div className="schedule1-container280">
                    <span className="schedule1-text219">1</span>
                  </div>
                  <div className="schedule1-container281">
                    <span className="schedule1-text220">1</span>
                  </div>
                </div>
                <div className="schedule1-container282">
                  <span className="schedule1-text221">Deep Clean</span>
                  <div className="schedule1-container283"></div>
                </div>
                <div className="schedule1-container284">
                  <span className="schedule1-text222">Regular Clean</span>
                  <div className="schedule1-container285"></div>
                </div>
                <div className="schedule1-container286">
                  <span className="schedule1-text223">Vacate Clean</span>
                  <div className="schedule1-container287"></div>
                </div>
                <div className="schedule1-container288">
                  <span className="schedule1-text224">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container289">
                <div className="schedule1-container290">
                  <span className="schedule1-text225">14</span>
                  <div className="schedule1-container291">
                    <span className="schedule1-text226">1</span>
                  </div>
                  <div className="schedule1-container292">
                    <span className="schedule1-text227">1</span>
                  </div>
                  <div className="schedule1-container293">
                    <span className="schedule1-text228">1</span>
                  </div>
                </div>
                <div className="schedule1-container294">
                  <span className="schedule1-text229">Deep Clean</span>
                  <div className="schedule1-container295"></div>
                </div>
                <div className="schedule1-container296">
                  <span className="schedule1-text230">Regular Clean</span>
                  <div className="schedule1-container297"></div>
                </div>
                <div className="schedule1-container298">
                  <span className="schedule1-text231">Vacate Clean</span>
                  <div className="schedule1-container299"></div>
                </div>
                <div className="schedule1-container300">
                  <span className="schedule1-text232">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule1-container301">
              <div className="schedule1-container302">
                <div className="schedule1-container303">
                  <span className="schedule1-text233">15</span>
                  <div className="schedule1-container304">
                    <span className="schedule1-text234">1</span>
                  </div>
                  <div className="schedule1-container305">
                    <span className="schedule1-text235">1</span>
                  </div>
                  <div className="schedule1-container306">
                    <span className="schedule1-text236">1</span>
                  </div>
                </div>
                <div className="schedule1-container307">
                  <span className="schedule1-text237">Deep Clean</span>
                  <div className="schedule1-container308"></div>
                </div>
                <div className="schedule1-container309">
                  <span className="schedule1-text238">Regular Clean</span>
                  <div className="schedule1-container310"></div>
                </div>
                <div className="schedule1-container311">
                  <span className="schedule1-text239">Vacate Clean</span>
                  <div className="schedule1-container312"></div>
                </div>
                <div className="schedule1-container313">
                  <span className="schedule1-text240">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container314">
                <div className="schedule1-container315">
                  <span className="schedule1-text241">16</span>
                  <div className="schedule1-container316">
                    <span className="schedule1-text242">1</span>
                  </div>
                  <div className="schedule1-container317">
                    <span className="schedule1-text243">1</span>
                  </div>
                  <div className="schedule1-container318">
                    <span className="schedule1-text244">1</span>
                  </div>
                </div>
                <div className="schedule1-container319">
                  <span className="schedule1-text245">Deep Clean</span>
                  <div className="schedule1-container320"></div>
                </div>
                <div className="schedule1-container321">
                  <span className="schedule1-text246">Regular Clean</span>
                  <div className="schedule1-container322"></div>
                </div>
                <div className="schedule1-container323">
                  <span className="schedule1-text247">Vacate Clean</span>
                  <div className="schedule1-container324"></div>
                </div>
                <div className="schedule1-container325">
                  <span className="schedule1-text248">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container326">
                <div className="schedule1-container327">
                  <span className="schedule1-text249">17</span>
                  <div className="schedule1-container328">
                    <span className="schedule1-text250">1</span>
                  </div>
                  <div className="schedule1-container329">
                    <span className="schedule1-text251">1</span>
                  </div>
                  <div className="schedule1-container330">
                    <span className="schedule1-text252">1</span>
                  </div>
                </div>
                <div className="schedule1-container331">
                  <span className="schedule1-text253">Deep Clean</span>
                  <div className="schedule1-container332"></div>
                </div>
                <div className="schedule1-container333">
                  <span className="schedule1-text254">Regular Clean</span>
                  <div className="schedule1-container334"></div>
                </div>
                <div className="schedule1-container335">
                  <span className="schedule1-text255">Vacate Clean</span>
                  <div className="schedule1-container336"></div>
                </div>
                <div className="schedule1-container337">
                  <span className="schedule1-text256">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container338">
                <div className="schedule1-container339">
                  <span className="schedule1-text257">18</span>
                  <div className="schedule1-container340">
                    <span className="schedule1-text258">1</span>
                  </div>
                  <div className="schedule1-container341">
                    <span className="schedule1-text259">1</span>
                  </div>
                  <div className="schedule1-container342">
                    <span className="schedule1-text260">1</span>
                  </div>
                </div>
                <div className="schedule1-container343">
                  <span className="schedule1-text261">Deep Clean</span>
                  <div className="schedule1-container344"></div>
                </div>
                <div className="schedule1-container345">
                  <span className="schedule1-text262">Regular Clean</span>
                  <div className="schedule1-container346"></div>
                </div>
                <div className="schedule1-container347">
                  <span className="schedule1-text263">Vacate Clean</span>
                  <div className="schedule1-container348"></div>
                </div>
                <div className="schedule1-container349">
                  <span className="schedule1-text264">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container350">
                <div className="schedule1-container351">
                  <span className="schedule1-text265">19</span>
                  <div className="schedule1-container352">
                    <span className="schedule1-text266">1</span>
                  </div>
                  <div className="schedule1-container353">
                    <span className="schedule1-text267">1</span>
                  </div>
                  <div className="schedule1-container354">
                    <span className="schedule1-text268">1</span>
                  </div>
                </div>
                <div className="schedule1-container355">
                  <span className="schedule1-text269">Deep Clean</span>
                  <div className="schedule1-container356"></div>
                </div>
                <div className="schedule1-container357">
                  <span className="schedule1-text270">Regular Clean</span>
                  <div className="schedule1-container358"></div>
                </div>
                <div className="schedule1-container359">
                  <span className="schedule1-text271">Vacate Clean</span>
                  <div className="schedule1-container360"></div>
                </div>
                <div className="schedule1-container361">
                  <span className="schedule1-text272">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container362">
                <div className="schedule1-container363">
                  <span className="schedule1-text273">20</span>
                  <div className="schedule1-container364">
                    <span className="schedule1-text274">1</span>
                  </div>
                  <div className="schedule1-container365">
                    <span className="schedule1-text275">1</span>
                  </div>
                  <div className="schedule1-container366">
                    <span className="schedule1-text276">1</span>
                  </div>
                </div>
                <div className="schedule1-container367">
                  <span className="schedule1-text277">Deep Clean</span>
                  <div className="schedule1-container368"></div>
                </div>
                <div className="schedule1-container369">
                  <span className="schedule1-text278">Regular Clean</span>
                  <div className="schedule1-container370"></div>
                </div>
                <div className="schedule1-container371">
                  <span className="schedule1-text279">Vacate Clean</span>
                  <div className="schedule1-container372"></div>
                </div>
                <div className="schedule1-container373">
                  <span className="schedule1-text280">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container374">
                <div className="schedule1-container375">
                  <span className="schedule1-text281">21</span>
                  <div className="schedule1-container376">
                    <span className="schedule1-text282">1</span>
                  </div>
                  <div className="schedule1-container377">
                    <span className="schedule1-text283">1</span>
                  </div>
                  <div className="schedule1-container378">
                    <span className="schedule1-text284">1</span>
                  </div>
                </div>
                <div className="schedule1-container379">
                  <span className="schedule1-text285">Deep Clean</span>
                  <div className="schedule1-container380"></div>
                </div>
                <div className="schedule1-container381">
                  <span className="schedule1-text286">Regular Clean</span>
                  <div className="schedule1-container382"></div>
                </div>
                <div className="schedule1-container383">
                  <span className="schedule1-text287">Vacate Clean</span>
                  <div className="schedule1-container384"></div>
                </div>
                <div className="schedule1-container385">
                  <span className="schedule1-text288">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule1-container386">
              <div className="schedule1-container387">
                <div className="schedule1-container388">
                  <span className="schedule1-text289">22</span>
                  <div className="schedule1-container389">
                    <span className="schedule1-text290">1</span>
                  </div>
                  <div className="schedule1-container390">
                    <span className="schedule1-text291">1</span>
                  </div>
                  <div className="schedule1-container391">
                    <span className="schedule1-text292">1</span>
                  </div>
                </div>
                <div className="schedule1-container392">
                  <span className="schedule1-text293">Deep Clean</span>
                  <div className="schedule1-container393"></div>
                </div>
                <div className="schedule1-container394">
                  <span className="schedule1-text294">Regular Clean</span>
                  <div className="schedule1-container395"></div>
                </div>
                <div className="schedule1-container396">
                  <span className="schedule1-text295">Vacate Clean</span>
                  <div className="schedule1-container397"></div>
                </div>
                <div className="schedule1-container398">
                  <span className="schedule1-text296">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container399">
                <div className="schedule1-container400">
                  <span className="schedule1-text297">23</span>
                  <div className="schedule1-container401">
                    <span className="schedule1-text298">1</span>
                  </div>
                  <div className="schedule1-container402">
                    <span className="schedule1-text299">1</span>
                  </div>
                  <div className="schedule1-container403">
                    <span className="schedule1-text300">1</span>
                  </div>
                </div>
                <div className="schedule1-container404">
                  <span className="schedule1-text301">Deep Clean</span>
                  <div className="schedule1-container405"></div>
                </div>
                <div className="schedule1-container406">
                  <span className="schedule1-text302">Regular Clean</span>
                  <div className="schedule1-container407"></div>
                </div>
                <div className="schedule1-container408">
                  <span className="schedule1-text303">Vacate Clean</span>
                  <div className="schedule1-container409"></div>
                </div>
                <div className="schedule1-container410">
                  <span className="schedule1-text304">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container411">
                <div className="schedule1-container412">
                  <span className="schedule1-text305">24</span>
                  <div className="schedule1-container413">
                    <span className="schedule1-text306">1</span>
                  </div>
                  <div className="schedule1-container414">
                    <span className="schedule1-text307">1</span>
                  </div>
                  <div className="schedule1-container415">
                    <span className="schedule1-text308">1</span>
                  </div>
                </div>
                <div className="schedule1-container416">
                  <span className="schedule1-text309">Deep Clean</span>
                  <div className="schedule1-container417"></div>
                </div>
                <div className="schedule1-container418">
                  <span className="schedule1-text310">Regular Clean</span>
                  <div className="schedule1-container419"></div>
                </div>
                <div className="schedule1-container420">
                  <span className="schedule1-text311">Vacate Clean</span>
                  <div className="schedule1-container421"></div>
                </div>
                <div className="schedule1-container422">
                  <span className="schedule1-text312">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container423">
                <div className="schedule1-container424">
                  <span className="schedule1-text313">25</span>
                  <div className="schedule1-container425">
                    <span className="schedule1-text314">1</span>
                  </div>
                  <div className="schedule1-container426">
                    <span className="schedule1-text315">1</span>
                  </div>
                  <div className="schedule1-container427">
                    <span className="schedule1-text316">1</span>
                  </div>
                </div>
                <div className="schedule1-container428">
                  <span className="schedule1-text317">Deep Clean</span>
                  <div className="schedule1-container429"></div>
                </div>
                <div className="schedule1-container430">
                  <span className="schedule1-text318">Regular Clean</span>
                  <div className="schedule1-container431"></div>
                </div>
                <div className="schedule1-container432">
                  <span className="schedule1-text319">Vacate Clean</span>
                  <div className="schedule1-container433"></div>
                </div>
                <div className="schedule1-container434">
                  <span className="schedule1-text320">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container435">
                <div className="schedule1-container436">
                  <span className="schedule1-text321">26</span>
                  <div className="schedule1-container437">
                    <span className="schedule1-text322">1</span>
                  </div>
                  <div className="schedule1-container438">
                    <span className="schedule1-text323">1</span>
                  </div>
                  <div className="schedule1-container439">
                    <span className="schedule1-text324">1</span>
                  </div>
                </div>
                <div className="schedule1-container440">
                  <span className="schedule1-text325">Deep Clean</span>
                  <div className="schedule1-container441"></div>
                </div>
                <div className="schedule1-container442">
                  <span className="schedule1-text326">Regular Clean</span>
                  <div className="schedule1-container443"></div>
                </div>
                <div className="schedule1-container444">
                  <span className="schedule1-text327">Vacate Clean</span>
                  <div className="schedule1-container445"></div>
                </div>
                <div className="schedule1-container446">
                  <span className="schedule1-text328">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container447">
                <div className="schedule1-container448">
                  <span className="schedule1-text329">27</span>
                  <div className="schedule1-container449">
                    <span className="schedule1-text330">1</span>
                  </div>
                  <div className="schedule1-container450">
                    <span className="schedule1-text331">1</span>
                  </div>
                  <div className="schedule1-container451">
                    <span className="schedule1-text332">1</span>
                  </div>
                </div>
                <div className="schedule1-container452">
                  <span className="schedule1-text333">Deep Clean</span>
                  <div className="schedule1-container453"></div>
                </div>
                <div className="schedule1-container454">
                  <span className="schedule1-text334">Regular Clean</span>
                  <div className="schedule1-container455"></div>
                </div>
                <div className="schedule1-container456">
                  <span className="schedule1-text335">Vacate Clean</span>
                  <div className="schedule1-container457"></div>
                </div>
                <div className="schedule1-container458">
                  <span className="schedule1-text336">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container459">
                <div className="schedule1-container460">
                  <span className="schedule1-text337">28</span>
                  <div className="schedule1-container461">
                    <span className="schedule1-text338">1</span>
                  </div>
                  <div className="schedule1-container462">
                    <span className="schedule1-text339">1</span>
                  </div>
                  <div className="schedule1-container463">
                    <span className="schedule1-text340">1</span>
                  </div>
                </div>
                <div className="schedule1-container464">
                  <span className="schedule1-text341">Deep Clean</span>
                  <div className="schedule1-container465"></div>
                </div>
                <div className="schedule1-container466">
                  <span className="schedule1-text342">Regular Clean</span>
                  <div className="schedule1-container467"></div>
                </div>
                <div className="schedule1-container468">
                  <span className="schedule1-text343">Vacate Clean</span>
                  <div className="schedule1-container469"></div>
                </div>
                <div className="schedule1-container470">
                  <span className="schedule1-text344">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule1-container471">
              <div className="schedule1-container472">
                <div className="schedule1-container473">
                  <span className="schedule1-text345">29</span>
                  <div className="schedule1-container474">
                    <span className="schedule1-text346">1</span>
                  </div>
                  <div className="schedule1-container475">
                    <span className="schedule1-text347">1</span>
                  </div>
                  <div className="schedule1-container476">
                    <span className="schedule1-text348">1</span>
                  </div>
                </div>
                <div className="schedule1-container477">
                  <span className="schedule1-text349">Deep Clean</span>
                  <div className="schedule1-container478"></div>
                </div>
                <div className="schedule1-container479">
                  <span className="schedule1-text350">Regular Clean</span>
                  <div className="schedule1-container480"></div>
                </div>
                <div className="schedule1-container481">
                  <span className="schedule1-text351">Vacate Clean</span>
                  <div className="schedule1-container482"></div>
                </div>
                <div className="schedule1-container483">
                  <span className="schedule1-text352">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container484">
                <div className="schedule1-container485">
                  <span className="schedule1-text353">30</span>
                  <div className="schedule1-container486">
                    <span className="schedule1-text354">1</span>
                  </div>
                  <div className="schedule1-container487">
                    <span className="schedule1-text355">1</span>
                  </div>
                  <div className="schedule1-container488">
                    <span className="schedule1-text356">1</span>
                  </div>
                </div>
                <div className="schedule1-container489">
                  <span className="schedule1-text357">Deep Clean</span>
                  <div className="schedule1-container490"></div>
                </div>
                <div className="schedule1-container491">
                  <span className="schedule1-text358">Regular Clean</span>
                  <div className="schedule1-container492"></div>
                </div>
                <div className="schedule1-container493">
                  <span className="schedule1-text359">Vacate Clean</span>
                  <div className="schedule1-container494"></div>
                </div>
                <div className="schedule1-container495">
                  <span className="schedule1-text360">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container496">
                <div className="schedule1-container497">
                  <span className="schedule1-text361">31</span>
                  <div className="schedule1-container498">
                    <span className="schedule1-text362">1</span>
                  </div>
                  <div className="schedule1-container499">
                    <span className="schedule1-text363">1</span>
                  </div>
                  <div className="schedule1-container500">
                    <span className="schedule1-text364">1</span>
                  </div>
                </div>
                <div className="schedule1-container501">
                  <span className="schedule1-text365">Deep Clean</span>
                  <div className="schedule1-container502"></div>
                </div>
                <div className="schedule1-container503">
                  <span className="schedule1-text366">Regular Clean</span>
                  <div className="schedule1-container504"></div>
                </div>
                <div className="schedule1-container505">
                  <span className="schedule1-text367">Vacate Clean</span>
                  <div className="schedule1-container506"></div>
                </div>
                <div className="schedule1-container507">
                  <span className="schedule1-text368">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container508">
                <div className="schedule1-container509">
                  <span className="schedule1-text369">1</span>
                  <div className="schedule1-container510">
                    <span className="schedule1-text370">1</span>
                  </div>
                  <div className="schedule1-container511">
                    <span className="schedule1-text371">1</span>
                  </div>
                  <div className="schedule1-container512">
                    <span className="schedule1-text372">1</span>
                  </div>
                </div>
                <div className="schedule1-container513">
                  <span className="schedule1-text373">Deep Clean</span>
                  <div className="schedule1-container514"></div>
                </div>
                <div className="schedule1-container515">
                  <span className="schedule1-text374">Regular Clean</span>
                  <div className="schedule1-container516"></div>
                </div>
                <div className="schedule1-container517">
                  <span className="schedule1-text375">Vacate Clean</span>
                  <div className="schedule1-container518"></div>
                </div>
                <div className="schedule1-container519">
                  <span className="schedule1-text376">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container520">
                <div className="schedule1-container521">
                  <span className="schedule1-text377">2</span>
                  <div className="schedule1-container522">
                    <span className="schedule1-text378">1</span>
                  </div>
                  <div className="schedule1-container523">
                    <span className="schedule1-text379">1</span>
                  </div>
                  <div className="schedule1-container524">
                    <span className="schedule1-text380">1</span>
                  </div>
                </div>
                <div className="schedule1-container525">
                  <span className="schedule1-text381">Deep Clean</span>
                  <div className="schedule1-container526"></div>
                </div>
                <div className="schedule1-container527">
                  <span className="schedule1-text382">Regular Clean</span>
                  <div className="schedule1-container528"></div>
                </div>
                <div className="schedule1-container529">
                  <span className="schedule1-text383">Vacate Clean</span>
                  <div className="schedule1-container530"></div>
                </div>
                <div className="schedule1-container531">
                  <span className="schedule1-text384">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container532">
                <div className="schedule1-container533">
                  <span className="schedule1-text385">3</span>
                  <div className="schedule1-container534">
                    <span className="schedule1-text386">1</span>
                  </div>
                  <div className="schedule1-container535">
                    <span className="schedule1-text387">1</span>
                  </div>
                  <div className="schedule1-container536">
                    <span className="schedule1-text388">1</span>
                  </div>
                </div>
                <div className="schedule1-container537">
                  <span className="schedule1-text389">Deep Clean</span>
                  <div className="schedule1-container538"></div>
                </div>
                <div className="schedule1-container539">
                  <span className="schedule1-text390">Regular Clean</span>
                  <div className="schedule1-container540"></div>
                </div>
                <div className="schedule1-container541">
                  <span className="schedule1-text391">Vacate Clean</span>
                  <div className="schedule1-container542"></div>
                </div>
                <div className="schedule1-container543">
                  <span className="schedule1-text392">Book Now</span>
                </div>
              </div>
              <div className="schedule1-container544">
                <div className="schedule1-container545">
                  <span className="schedule1-text393">4</span>
                  <div className="schedule1-container546">
                    <span className="schedule1-text394">1</span>
                  </div>
                  <div className="schedule1-container547">
                    <span className="schedule1-text395">1</span>
                  </div>
                  <div className="schedule1-container548">
                    <span className="schedule1-text396">1</span>
                  </div>
                </div>
                <div className="schedule1-container549">
                  <span className="schedule1-text397">Deep Clean</span>
                  <div className="schedule1-container550"></div>
                </div>
                <div className="schedule1-container551">
                  <span className="schedule1-text398">Regular Clean</span>
                  <div className="schedule1-container552"></div>
                </div>
                <div className="schedule1-container553">
                  <span className="schedule1-text399">Vacate Clean</span>
                  <div className="schedule1-container554"></div>
                </div>
                <div className="schedule1-container555">
                  <span className="schedule1-text400">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule1-container556">
              <div className="schedule1-container557">
                <div className="schedule1-container558">
                  <div className="schedule1-container559">
                    <img
                      alt="image"
                      src={require("./img/calenderx-200h.png")}
                      className="schedule1-image22"
                    />
                    <span className="schedule1-text401">1st January, 2024</span>
                  </div>
                  <div className="schedule1-container560">
                    <div className="schedule1-container561">
                      <div className="schedule1-container562"></div>
                    </div>
                    <div className="schedule1-container563">
                      <span className="schedule1-text402">
                        Deep Clean for apartment
                      </span>
                      <span className="schedule1-text403">
                        Completed Jan 20th
                      </span>
                      <div className="schedule1-container564">
                        <span className="schedule1-text404">1X Bathroom</span>
                        <span className="schedule1-text405">5X Kitchen</span>
                        <span className="schedule1-text406">7X Bedroom</span>
                        <span className="schedule1-text407">1X Microwave</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="schedule1-container565">
                  <div className="schedule1-container566">
                    <span className="schedule1-text408">Amend</span>
                  </div>
                  <div className="schedule1-container567">
                    <span className="schedule1-text409">Cancel</span>
                  </div>
                </div>
              </div>
              <div className="schedule1-container568">
                <div className="schedule1-container569">
                  <div className="schedule1-container570">
                    <img
                      alt="image"
                      src={require("./img/calenderx-200h.png")}
                      className="schedule1-image23"
                    />
                    <span className="schedule1-text410">
                      12th January, 2024
                    </span>
                  </div>
                  <div className="schedule1-container571">
                    <div className="schedule1-container572">
                      <div className="schedule1-container573"></div>
                    </div>
                    <div className="schedule1-container574">
                      <span className="schedule1-text411">
                        Deep Clean for apartment
                      </span>
                      <span className="schedule1-text412">
                        Completed Jan 20th
                      </span>
                      <div className="schedule1-container575">
                        <span className="schedule1-text413">1X Bathroom</span>
                        <span className="schedule1-text414">5X Kitchen</span>
                        <span className="schedule1-text415">7X Bedroom</span>
                        <span className="schedule1-text416">1X Microwave</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="schedule1-container576">
                  <div className="schedule1-container577">
                    <span className="schedule1-text417">Amend</span>
                  </div>
                  <div className="schedule1-container578">
                    <span className="schedule1-text418">Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Schedule1
