import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './referral.css'

const Referral = (props) => {
  return (
    <div className="referral-container10">
      <Helmet>
        <title>referral - Crips Cleaning</title>
        <meta
          name="description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
        <meta property="og:title" content="referral - Crips Cleaning" />
        <meta
          property="og:description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
      </Helmet>
      <div className="referral-container11">
        <img
          alt="image"
          src={require("./img/logo-200h.png")}
          className="referral-image10"
        />
        <div className="referral-container12">
          <span className="referral-text10">OVERVIEW</span>
          <Link to="/dashboard" className="referral-navlink1">
            <div className="referral-container13">
              <img
                alt="image"
                src={require("./img/homep-200h.png")}
                className="referral-image11"
              />
              <span className="referral-text11">Dashboard</span>
            </div>
          </Link>
          <Link to="/schedule" className="referral-navlink2">
            <div className="referral-container14">
              <img
                alt="image"
                src={require("./img/calenderx-200h.png")}
                className="referral-image12"
              />
              <span className="referral-text12">Schedule</span>
            </div>
          </Link>
          <div className="referral-container15">
            <img
              alt="image"
              src={require("./img/link-200h.png")}
              className="referral-image13"
            />
            <span className="referral-text13">Referrals</span>
          </div>
          <Link to="/reward" className="referral-navlink3">
            <div className="referral-container16">
              <img
                alt="image"
                src={require("./img/lock1-200h.png")}
                className="referral-image14"
              />
              <span className="referral-text14">Rewards</span>
            </div>
          </Link>
          <Link to="/cleanerspass" className="referral-navlink4">
            <div className="referral-container17">
              <img
                alt="image"
                src={require("./img/key-200h.png")}
                className="referral-image15"
              />
              <span className="referral-text15">CleanPass</span>
            </div>
          </Link>
        </div>
        <div className="referral-container18">
          <span className="referral-text16">SETTINGS</span>
          <Link to="/settings" className="referral-navlink5">
            <div className="referral-container19">
              <img
                alt="image"
                src={require("./img/settings_x-200h.png")}
                className="referral-image16"
              />
              <span className="referral-text17">Settings</span>
            </div>
          </Link>
          <div className="referral-container20">
            <img
              alt="image"
              src={require("./img/exitx-200h.png")}
              className="referral-image17"
            />
            <span className="referral-text18">Logout</span>
          </div>
        </div>
      </div>
      <div className="referral-container21">
        <div className="referral-container22">
          <span className="referral-text19">Referrals</span>
          <img
            alt="image"
            src={require("./img/question-200h.png")}
            className="referral-image18"
          />
          <div className="referral-container23">
            <img
              alt="image"
              src={require("./img/search-200h.png")}
              className="referral-image19"
            />
            <span className="referral-text20">Search for anything...</span>
            <input type="text" className="referral-textinput1 input" />
          </div>
          <div className="referral-container24">
            <span className="referral-text21">Book Now</span>
          </div>
        </div>
        <div className="referral-container25">
          <div className="referral-container26">
            <div className="referral-container27">
              <span className="referral-text22">Earn with Crisp Cleaning</span>
              <span className="referral-text23">
                Invite your friends to Crisp Cleaning, if they sign up, you and
                your friend will gain 30% OFF your next 2 cleans!
              </span>
              <div className="referral-container28">
                <div className="referral-container29">
                  <img
                    alt="image"
                    src={require("./img/sendinv-200h.png")}
                    className="referral-image20"
                  />
                  <span className="referral-text24">Send Invitation</span>
                  <span className="referral-text25">
                    <span>
                      Send referral link to friends and tell
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>
                      them how useful Crisp Cleaning is!
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
                <img
                  alt="image"
                  src={require("./img/vector69-200h.png")}
                  className="referral-image21"
                />
                <div className="referral-container30">
                  <img
                    alt="image"
                    src={require("./img/addinv-200h.png")}
                    className="referral-image22"
                  />
                  <span className="referral-text29">Send Invitation</span>
                  <span className="referral-text30">
                    <span>Let your friends register to our services</span>
                    <br></br>
                    <span>using your personal referral code!</span>
                  </span>
                </div>
                <img
                  alt="image"
                  src={require("./img/vector70-200h.png")}
                  className="referral-image23"
                />
                <div className="referral-container31">
                  <img
                    alt="image"
                    src={require("./img/addinv-200h.png")}
                    className="referral-image24"
                  />
                  <span className="referral-text34">Send Invitation</span>
                  <span className="referral-text35">
                    <span>You and your friends gain 30% OFF</span>
                    <br></br>
                    <span>your next 2 cleans!</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="referral-container32">
              <span className="referral-text39">Invite your friends!</span>
              <span className="referral-text40">
                Add your friends email addresses and send them invitations to
                join!
              </span>
              <div className="referral-container33">
                <input
                  type="text"
                  placeholder="Email addresses..."
                  className="referral-textinput2 input"
                />
                <img
                  alt="image"
                  src={require("./img/send-900w.png")}
                  className="referral-image25"
                />
              </div>
              <span className="referral-text41">Share the referral link</span>
              <span className="referral-text42">
                You can also share your referral link by copying it and sending
                it to your friends.
              </span>
              <div className="referral-container34">
                <input
                  type="text"
                  value="HS7WN29AJ29MQ9s9uU28N"
                  placeholder="Email addresses..."
                  className="referral-textinput3 input"
                />
                <img
                  alt="image"
                  src={require("./img/copy-900w.png")}
                  className="referral-image26"
                />
              </div>
            </div>
          </div>
          <div className="referral-container35">
            <div className="referral-container36">
              <div className="referral-container37">
                <div className="referral-container38">
                  <div className="referral-container39">
                    <span className="referral-text43">
                      <span>People you have reffered</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="referral-container40">
                    <span className="referral-text46">Date they joined</span>
                  </div>
                  <div className="referral-container41">
                    <span className="referral-text47">Membership</span>
                  </div>
                </div>
                <div className="referral-container42">
                  <div className="referral-container43">
                    <img
                      alt="image"
                      src={require("./img/fullperson-200w.png")}
                      className="referral-image27"
                    />
                    <span className="referral-text48">Alex Johnson</span>
                  </div>
                  <div className="referral-container44">
                    <span className="referral-text49">121/01/2024</span>
                  </div>
                  <div className="referral-container45">
                    <span className="referral-text50">Cleaners Pass</span>
                  </div>
                </div>
                <div className="referral-container46">
                  <div className="referral-container47">
                    <img
                      alt="image"
                      src={require("./img/fullperson-200w.png")}
                      className="referral-image28"
                    />
                    <span className="referral-text51">Alex Johnson</span>
                  </div>
                  <div className="referral-container48">
                    <span className="referral-text52">121/01/2024</span>
                  </div>
                  <div className="referral-container49">
                    <span className="referral-text53">Cleaners Pass</span>
                  </div>
                </div>
                <div className="referral-container50">
                  <div className="referral-container51">
                    <img
                      alt="image"
                      src={require("./img/fullperson-200w.png")}
                      className="referral-image29"
                    />
                    <span className="referral-text54">Alex Johnson</span>
                  </div>
                  <div className="referral-container52">
                    <span className="referral-text55">121/01/2024</span>
                  </div>
                  <div className="referral-container53">
                    <span className="referral-text56">Cleaners Pass</span>
                  </div>
                </div>
                <div className="referral-container54">
                  <div className="referral-container55">
                    <img
                      alt="image"
                      src={require("./img/fullperson-200w.png")}
                      className="referral-image30"
                    />
                    <span className="referral-text57">Alex Johnson</span>
                  </div>
                  <div className="referral-container56">
                    <span className="referral-text58">121/01/2024</span>
                  </div>
                  <div className="referral-container57">
                    <span className="referral-text59">Cleaners Pass</span>
                  </div>
                </div>
                <div className="referral-container58">
                  <div className="referral-container59">
                    <img
                      alt="image"
                      src={require("./img/fullperson-200w.png")}
                      className="referral-image31"
                    />
                    <span className="referral-text60">Alex Johnson</span>
                  </div>
                  <div className="referral-container60">
                    <span className="referral-text61">121/01/2024</span>
                  </div>
                  <div className="referral-container61">
                    <span className="referral-text62">Cleaners Pass</span>
                  </div>
                </div>
                <div className="referral-container62">
                  <div className="referral-container63">
                    <img
                      alt="image"
                      src={require("./img/fullperson-200w.png")}
                      className="referral-image32"
                    />
                    <span className="referral-text63">Alex Johnson</span>
                  </div>
                  <div className="referral-container64">
                    <span className="referral-text64">121/01/2024</span>
                  </div>
                  <div className="referral-container65">
                    <span className="referral-text65">Cleaners Pass</span>
                  </div>
                </div>
                <div className="referral-container66">
                  <div className="referral-container67">
                    <img
                      alt="image"
                      src={require("./img/fullperson-200w.png")}
                      className="referral-image33"
                    />
                    <span className="referral-text66">Alex Johnson</span>
                  </div>
                  <div className="referral-container68">
                    <span className="referral-text67">121/01/2024</span>
                  </div>
                  <div className="referral-container69">
                    <span className="referral-text68">Cleaners Pass</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="referral-container70">
              <div className="referral-container71">
                <span className="referral-text69">Share to other apps</span>
                <span className="referral-text70">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </span>
                <div className="referral-container72">
                  <div className="referral-container73">
                    <img
                      alt="image"
                      src={require("./img/instagram-200h.png")}
                      className="referral-image34"
                    />
                    <span className="referral-text71">Instagram</span>
                  </div>
                  <div className="referral-container74">
                    <img
                      alt="image"
                      src={require("./img/message1-200h.png")}
                      className="referral-image35"
                    />
                    <span className="referral-text72">
                      <span>Messages</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="referral-container75">
                    <img
                      alt="image"
                      src={require("./img/discord-200h.png")}
                      className="referral-image36"
                    />
                    <span className="referral-text75">
                      <span>Discord</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="referral-container76">
                    <img
                      alt="image"
                      src={require("./img/whatsapp-200h.png")}
                      className="referral-image37"
                    />
                    <span className="referral-text78">
                      <span>Whatsapp</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="referral-container77">
                    <img
                      alt="image"
                      src={require("./img/notebook-200h.png")}
                      className="referral-image38"
                    />
                    <span className="referral-text81">Notes</span>
                  </div>
                  <div className="referral-container78">
                    <img
                      alt="image"
                      src={require("./img/telegram-200h.png")}
                      className="referral-image39"
                    />
                    <span className="referral-text82">Notes</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Referral
