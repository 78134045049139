import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './transaction.css'

const Transaction = (props) => {
  return (
    <div className="transaction-container10">
      <Helmet>
        <title>transaction - Crips Cleaning</title>
        <meta
          name="description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
        <meta property="og:title" content="transaction - Crips Cleaning" />
        <meta
          property="og:description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
      </Helmet>
      <div className="transaction-container11">
        <img
          alt="image"
          src={require("./img/logo-200h.png")}
          className="transaction-image10"
        />
        <div className="transaction-container12">
          <span className="transaction-text10">OVERVIEW</span>
          <div className="transaction-container13">
            <img
              alt="image"
              src={require("./img/homep-200h.png")}
              className="transaction-image11"
            />
            <span className="transaction-text11">Dashboard</span>
          </div>
          <Link to="/schedule" className="transaction-navlink1">
            <div className="transaction-container14">
              <img
                alt="image"
                src={require("./img/calenderx-200h.png")}
                className="transaction-image12"
              />
              <span className="transaction-text12">Schedule</span>
            </div>
          </Link>
          <Link to="/referral" className="transaction-navlink2">
            <div className="transaction-container15">
              <img
                alt="image"
                src={require("./img/link-200h.png")}
                className="transaction-image13"
              />
              <span className="transaction-text13">Referrals</span>
            </div>
          </Link>
          <Link to="/reward" className="transaction-navlink3">
            <div className="transaction-container16">
              <img
                alt="image"
                src={require("./img/lock1-200h.png")}
                className="transaction-image14"
              />
              <span className="transaction-text14">Rewards</span>
            </div>
          </Link>
          <Link to="/cleanerspass" className="transaction-navlink4">
            <div className="transaction-container17">
              <img
                alt="image"
                src={require("./img/key-200h.png")}
                className="transaction-image15"
              />
              <span className="transaction-text15">CleanPass</span>
            </div>
          </Link>
        </div>
        <div className="transaction-container18">
          <span className="transaction-text16">SETTINGS</span>
          <Link to="/settings" className="transaction-navlink5">
            <div className="transaction-container19">
              <img
                alt="image"
                src={require("./img/settings_x-200h.png")}
                className="transaction-image16"
              />
              <span className="transaction-text17">Settings</span>
            </div>
          </Link>
          <div className="transaction-container20">
            <img
              alt="image"
              src={require("./img/exitx-200h.png")}
              className="transaction-image17"
            />
            <span className="transaction-text18">Logout</span>
          </div>
        </div>
      </div>
      <div className="transaction-container21">
        <div className="transaction-container22">
          <span className="transaction-text19">Transactions</span>
          <div className="transaction-container23">
            <img
              alt="image"
              src={require("./img/question-200h.png")}
              className="transaction-image18"
            />
            <div className="transaction-container24">
              <img
                alt="image"
                src={require("./img/search-200h.png")}
                className="transaction-image19"
              />
              <span className="transaction-text20">Search for anything...</span>
              <input type="text" className="transaction-textinput input" />
            </div>
          </div>
          <div className="transaction-container25">
            <span className="transaction-text21">Book Now</span>
          </div>
          <Link to="/settings" className="transaction-navlink6">
            <img
              alt="image"
              src={require("./img/setting-200h.png")}
              className="transaction-image20"
            />
          </Link>
        </div>
        <div className="transaction-container26">
          <div className="transaction-container27">
            <span className="transaction-text22">
              <span>
                View and manage all the tranactions for your account
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="transaction-text24">(account name).</span>
            </span>
          </div>
          <div className="transaction-container28">
            <div className="transaction-container29">
              <span className="transaction-text25">Subscription</span>
            </div>
            <div className="transaction-container30">
              <span className="transaction-text26">Last Payment</span>
            </div>
            <div className="transaction-container31">
              <span className="transaction-text27">Upcoming Payment</span>
            </div>
            <div className="transaction-container32">
              <span className="transaction-text28">Payment Method</span>
            </div>
            <div className="transaction-container33">
              <span className="transaction-text29">Status</span>
            </div>
            <div className="transaction-container34"></div>
          </div>
          <div className="transaction-container35">
            <div className="transaction-container36">
              <span className="transaction-text30">Cleaner’s Pass</span>
            </div>
            <div className="transaction-container37">
              <span className="transaction-text31">
                <span>Apr 29, 2024</span>
                <br></br>
                <span className="transaction-text34">view invoice</span>
              </span>
            </div>
            <div className="transaction-container38">
              <span className="transaction-text35">
                <span>May 29, 2024</span>
                <br></br>
                <span className="transaction-text38">view payment details</span>
              </span>
            </div>
            <div className="transaction-container39">
              <img
                alt="image"
                src={require("./img/mastercard-200h.png")}
                className="transaction-image21"
              />
              <span className="transaction-text39">••••8642</span>
            </div>
            <div className="transaction-container40">
              <span className="transaction-text40">active</span>
            </div>
            <div className="transaction-container41">
              <span className="transaction-text41">Update Payment Method</span>
            </div>
          </div>
          <span className="transaction-text42">Cleaning History</span>
          <div className="transaction-container42">
            <div className="transaction-container43">
              <span className="transaction-text43">Date of clean</span>
            </div>
            <div className="transaction-container44">
              <span className="transaction-text44">Price</span>
            </div>
            <div className="transaction-container45">
              <span className="transaction-text45">Type of clean</span>
            </div>
            <div className="transaction-container46">
              <span className="transaction-text46">Clean details</span>
            </div>
          </div>
          <div className="transaction-container47">
            <div className="transaction-container48">
              <div className="transaction-container49">
                <span className="transaction-text47">21st Sep 2024</span>
              </div>
              <div className="transaction-container50">
                <span className="transaction-text48">$120.99</span>
              </div>
              <div className="transaction-container51">
                <span className="transaction-text49">Vacate clean</span>
              </div>
              <div className="transaction-container52">
                <span className="transaction-text50">
                  Rooms = (1x bathroom, 2x kitchen, 1x bedroom)
                </span>
              </div>
              <div className="transaction-container53">
                <span className="transaction-text51">Leave a review</span>
              </div>
              <div className="transaction-container54">
                <span className="transaction-text52">Tip the cleaner</span>
              </div>
            </div>
            <div className="transaction-container55">
              <div className="transaction-container56">
                <span className="transaction-text53">21st Sep 2024</span>
              </div>
              <div className="transaction-container57">
                <span className="transaction-text54">$120.99</span>
              </div>
              <div className="transaction-container58">
                <span className="transaction-text55">Vacate clean</span>
              </div>
              <div className="transaction-container59">
                <span className="transaction-text56">
                  Rooms = (1x bathroom, 2x kitchen, 1x bedroom)
                </span>
              </div>
              <div className="transaction-container60">
                <span className="transaction-text57">Leave a review</span>
              </div>
              <div className="transaction-container61">
                <span className="transaction-text58">Tip the cleaner</span>
              </div>
            </div>
            <div className="transaction-container62">
              <div className="transaction-container63">
                <span className="transaction-text59">21st Sep 2024</span>
              </div>
              <div className="transaction-container64">
                <span className="transaction-text60">$120.99</span>
              </div>
              <div className="transaction-container65">
                <span className="transaction-text61">Vacate clean</span>
              </div>
              <div className="transaction-container66">
                <span className="transaction-text62">
                  Rooms = (1x bathroom, 2x kitchen, 1x bedroom)
                </span>
              </div>
              <div className="transaction-container67">
                <span className="transaction-text63">Leave a review</span>
              </div>
              <div className="transaction-container68">
                <span className="transaction-text64">Tip the cleaner</span>
              </div>
            </div>
            <div className="transaction-container69">
              <div className="transaction-container70">
                <span className="transaction-text65">21st Sep 2024</span>
              </div>
              <div className="transaction-container71">
                <span className="transaction-text66">$120.99</span>
              </div>
              <div className="transaction-container72">
                <span className="transaction-text67">Vacate clean</span>
              </div>
              <div className="transaction-container73">
                <span className="transaction-text68">
                  Rooms = (1x bathroom, 2x kitchen, 1x bedroom)
                </span>
              </div>
              <div className="transaction-container74">
                <span className="transaction-text69">Leave a review</span>
              </div>
              <div className="transaction-container75">
                <span className="transaction-text70">Tip the cleaner</span>
              </div>
            </div>
            <div className="transaction-container76">
              <div className="transaction-container77">
                <span className="transaction-text71">21st Sep 2024</span>
              </div>
              <div className="transaction-container78">
                <span className="transaction-text72">$120.99</span>
              </div>
              <div className="transaction-container79">
                <span className="transaction-text73">Vacate clean</span>
              </div>
              <div className="transaction-container80">
                <span className="transaction-text74">
                  Rooms = (1x bathroom, 2x kitchen, 1x bedroom)
                </span>
              </div>
              <div className="transaction-container81">
                <span className="transaction-text75">Leave a review</span>
              </div>
              <div className="transaction-container82">
                <span className="transaction-text76">Tip the cleaner</span>
              </div>
            </div>
            <div className="transaction-container83">
              <div className="transaction-container84">
                <span className="transaction-text77">21st Sep 2024</span>
              </div>
              <div className="transaction-container85">
                <span className="transaction-text78">$120.99</span>
              </div>
              <div className="transaction-container86">
                <span className="transaction-text79">Vacate clean</span>
              </div>
              <div className="transaction-container87">
                <span className="transaction-text80">
                  Rooms = (1x bathroom, 2x kitchen, 1x bedroom)
                </span>
              </div>
              <div className="transaction-container88">
                <span className="transaction-text81">Leave a review</span>
              </div>
              <div className="transaction-container89">
                <span className="transaction-text82">Tip the cleaner</span>
              </div>
            </div>
            <div className="transaction-container90">
              <div className="transaction-container91">
                <span className="transaction-text83">Go Back</span>
              </div>
              <div className="transaction-container92">
                <span className="transaction-text84">View More</span>
                <img
                  alt="image"
                  src={require("./img/arrow1-1500w.png")}
                  className="transaction-image22"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Transaction
