import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './schedule.css'

const Schedule = (props) => {
  return (
    <div className="schedule-container100">
      <Helmet>
        <title>Schedule - Crips Cleaning</title>
        <meta
          name="description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
        <meta property="og:title" content="Schedule - Crips Cleaning" />
        <meta
          property="og:description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
      </Helmet>
      <div className="schedule-container101">
        <img
          alt="image"
          src={require("./img/logo-200h.png")}
          className="schedule-image10"
        />
        <div className="schedule-container102">
          <span className="schedule-text100">OVERVIEW</span>
          <Link to="/dashboard" className="schedule-navlink1">
            <div className="schedule-container103">
              <img
                alt="image"
                src={require("./img/homep-200h.png")}
                className="schedule-image11"
              />
              <span className="schedule-text101">Dashboard</span>
            </div>
          </Link>
          <div className="schedule-container104">
            <img
              alt="image"
              src={require("./img/calenderx-200h.png")}
              className="schedule-image12"
            />
            <span className="schedule-text102">Schedule</span>
          </div>
          <Link to="/referral" className="schedule-navlink2">
            <div className="schedule-container105">
              <img
                alt="image"
                src={require("./img/link-200h.png")}
                className="schedule-image13"
              />
              <span className="schedule-text103">Referrals</span>
            </div>
          </Link>
          <Link to="/reward" className="schedule-navlink3">
            <div className="schedule-container106">
              <img
                alt="image"
                src={require("./img/lock1-200h.png")}
                className="schedule-image14"
              />
              <span className="schedule-text104">Rewards</span>
            </div>
          </Link>
          <Link to="/cleanerspass" className="schedule-navlink4">
            <div className="schedule-container107">
              <img
                alt="image"
                src={require("./img/key-200h.png")}
                className="schedule-image15"
              />
              <span className="schedule-text105">CleanPass</span>
            </div>
          </Link>
        </div>
        <div className="schedule-container108">
          <span className="schedule-text106">SETTINGS</span>
          <Link to="/settings" className="schedule-navlink5">
            <div className="schedule-container109">
              <img
                alt="image"
                src={require("./img/settings_x-200h.png")}
                className="schedule-image16"
              />
              <span className="schedule-text107">Settings</span>
            </div>
          </Link>
          <div className="schedule-container110">
            <img
              alt="image"
              src={require("./img/exitx-200h.png")}
              className="schedule-image17"
            />
            <span className="schedule-text108">Logout</span>
          </div>
        </div>
      </div>
      <div className="schedule-container111">
        <div className="schedule-container112">
          <span className="schedule-text109">Schedule</span>
          <img
            alt="image"
            src={require("./img/question-200h.png")}
            className="schedule-image18"
          />
          <div className="schedule-container113">
            <img
              alt="image"
              src={require("./img/search-200h.png")}
              className="schedule-image19"
            />
            <span className="schedule-text110">Search for anything...</span>
            <input type="text" className="schedule-textinput input" />
          </div>
          <div className="schedule-container114">
            <span className="schedule-text111">Book Now</span>
          </div>
        </div>
        <div className="schedule-container115">
          <div className="schedule-container116">
            <div className="schedule-container117">
              <div className="schedule-container118">
                <span className="schedule-text112">Monthly</span>
                <Link to="/schedule1" className="schedule-navlink6">
                  Fortnightly
                </Link>
              </div>
              <div className="schedule-container119">
                <span className="schedule-text113">January 2024</span>
                <div className="schedule-container120">
                  <div className="schedule-container121">
                    <img
                      alt="image"
                      src={require("./img/left-200w.png")}
                      className="schedule-image20"
                    />
                  </div>
                  <div className="schedule-container122">
                    <img
                      alt="image"
                      src={require("./img/right-200w.png")}
                      className="schedule-image21"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="schedule-container123">
              <div className="schedule-container124">
                <span className="schedule-text114">MON</span>
              </div>
              <div className="schedule-container125">
                <span className="schedule-text115">TUE</span>
              </div>
              <div className="schedule-container126">
                <span className="schedule-text116">WED</span>
              </div>
              <div className="schedule-container127">
                <span className="schedule-text117">THU</span>
              </div>
              <div className="schedule-container128">
                <span className="schedule-text118">FRI</span>
              </div>
              <div className="schedule-container129">
                <span className="schedule-text119">SAT</span>
              </div>
              <div className="schedule-container130">
                <span className="schedule-text120">SUN</span>
              </div>
            </div>
            <div className="schedule-container131">
              <div className="schedule-container132">
                <div className="schedule-container133">
                  <span className="schedule-text121">1</span>
                  <div className="schedule-container134">
                    <span className="schedule-text122">1</span>
                  </div>
                  <div className="schedule-container135">
                    <span className="schedule-text123">1</span>
                  </div>
                  <div className="schedule-container136">
                    <span className="schedule-text124">1</span>
                  </div>
                </div>
                <div className="schedule-container137">
                  <span className="schedule-text125">Deep Clean</span>
                  <div className="schedule-container138"></div>
                </div>
                <div className="schedule-container139">
                  <span className="schedule-text126">Regular Clean</span>
                  <div className="schedule-container140"></div>
                </div>
                <div className="schedule-container141">
                  <span className="schedule-text127">Vacate Clean</span>
                  <div className="schedule-container142"></div>
                </div>
                <div className="schedule-container143">
                  <span className="schedule-text128">Book Now</span>
                </div>
              </div>
              <div className="schedule-container144">
                <div className="schedule-container145">
                  <span className="schedule-text129">2</span>
                  <div className="schedule-container146">
                    <span className="schedule-text130">1</span>
                  </div>
                  <div className="schedule-container147">
                    <span className="schedule-text131">1</span>
                  </div>
                  <div className="schedule-container148">
                    <span className="schedule-text132">1</span>
                  </div>
                </div>
                <div className="schedule-container149">
                  <span className="schedule-text133">Deep Clean</span>
                  <div className="schedule-container150"></div>
                </div>
                <div className="schedule-container151">
                  <span className="schedule-text134">Regular Clean</span>
                  <div className="schedule-container152"></div>
                </div>
                <div className="schedule-container153">
                  <span className="schedule-text135">Vacate Clean</span>
                  <div className="schedule-container154"></div>
                </div>
                <div className="schedule-container155">
                  <span className="schedule-text136">Book Now</span>
                </div>
              </div>
              <div className="schedule-container156">
                <div className="schedule-container157">
                  <span className="schedule-text137">3</span>
                  <div className="schedule-container158">
                    <span className="schedule-text138">1</span>
                  </div>
                  <div className="schedule-container159">
                    <span className="schedule-text139">1</span>
                  </div>
                  <div className="schedule-container160">
                    <span className="schedule-text140">1</span>
                  </div>
                </div>
                <div className="schedule-container161">
                  <span className="schedule-text141">Deep Clean</span>
                  <div className="schedule-container162"></div>
                </div>
                <div className="schedule-container163">
                  <span className="schedule-text142">Regular Clean</span>
                  <div className="schedule-container164"></div>
                </div>
                <div className="schedule-container165">
                  <span className="schedule-text143">Vacate Clean</span>
                  <div className="schedule-container166"></div>
                </div>
                <div className="schedule-container167">
                  <span className="schedule-text144">Book Now</span>
                </div>
              </div>
              <div className="schedule-container168">
                <div className="schedule-container169">
                  <span className="schedule-text145">4</span>
                  <div className="schedule-container170">
                    <span className="schedule-text146">1</span>
                  </div>
                  <div className="schedule-container171">
                    <span className="schedule-text147">1</span>
                  </div>
                  <div className="schedule-container172">
                    <span className="schedule-text148">1</span>
                  </div>
                </div>
                <div className="schedule-container173">
                  <span className="schedule-text149">Deep Clean</span>
                  <div className="schedule-container174"></div>
                </div>
                <div className="schedule-container175">
                  <span className="schedule-text150">Regular Clean</span>
                  <div className="schedule-container176"></div>
                </div>
                <div className="schedule-container177">
                  <span className="schedule-text151">Vacate Clean</span>
                  <div className="schedule-container178"></div>
                </div>
                <div className="schedule-container179">
                  <span className="schedule-text152">Book Now</span>
                </div>
              </div>
              <div className="schedule-container180">
                <div className="schedule-container181">
                  <span className="schedule-text153">5</span>
                  <div className="schedule-container182">
                    <span className="schedule-text154">1</span>
                  </div>
                  <div className="schedule-container183">
                    <span className="schedule-text155">1</span>
                  </div>
                  <div className="schedule-container184">
                    <span className="schedule-text156">1</span>
                  </div>
                </div>
                <div className="schedule-container185">
                  <span className="schedule-text157">Deep Clean</span>
                  <div className="schedule-container186"></div>
                </div>
                <div className="schedule-container187">
                  <span className="schedule-text158">Regular Clean</span>
                  <div className="schedule-container188"></div>
                </div>
                <div className="schedule-container189">
                  <span className="schedule-text159">Vacate Clean</span>
                  <div className="schedule-container190"></div>
                </div>
                <div className="schedule-container191">
                  <span className="schedule-text160">Book Now</span>
                </div>
              </div>
              <div className="schedule-container192">
                <div className="schedule-container193">
                  <span className="schedule-text161">6</span>
                  <div className="schedule-container194">
                    <span className="schedule-text162">1</span>
                  </div>
                  <div className="schedule-container195">
                    <span className="schedule-text163">1</span>
                  </div>
                  <div className="schedule-container196">
                    <span className="schedule-text164">1</span>
                  </div>
                </div>
                <div className="schedule-container197">
                  <span className="schedule-text165">Deep Clean</span>
                  <div className="schedule-container198"></div>
                </div>
                <div className="schedule-container199">
                  <span className="schedule-text166">Regular Clean</span>
                  <div className="schedule-container200"></div>
                </div>
                <div className="schedule-container201">
                  <span className="schedule-text167">Vacate Clean</span>
                  <div className="schedule-container202"></div>
                </div>
                <div className="schedule-container203">
                  <span className="schedule-text168">Book Now</span>
                </div>
              </div>
              <div className="schedule-container204">
                <div className="schedule-container205">
                  <span className="schedule-text169">7</span>
                  <div className="schedule-container206">
                    <span className="schedule-text170">1</span>
                  </div>
                  <div className="schedule-container207">
                    <span className="schedule-text171">1</span>
                  </div>
                  <div className="schedule-container208">
                    <span className="schedule-text172">1</span>
                  </div>
                </div>
                <div className="schedule-container209">
                  <span className="schedule-text173">Deep Clean</span>
                  <div className="schedule-container210"></div>
                </div>
                <div className="schedule-container211">
                  <span className="schedule-text174">Regular Clean</span>
                  <div className="schedule-container212"></div>
                </div>
                <div className="schedule-container213">
                  <span className="schedule-text175">Vacate Clean</span>
                  <div className="schedule-container214"></div>
                </div>
                <div className="schedule-container215">
                  <span className="schedule-text176">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule-container216">
              <div className="schedule-container217">
                <div className="schedule-container218">
                  <span className="schedule-text177">8</span>
                  <div className="schedule-container219">
                    <span className="schedule-text178">1</span>
                  </div>
                  <div className="schedule-container220">
                    <span className="schedule-text179">1</span>
                  </div>
                  <div className="schedule-container221">
                    <span className="schedule-text180">1</span>
                  </div>
                </div>
                <div className="schedule-container222">
                  <span className="schedule-text181">Deep Clean</span>
                  <div className="schedule-container223"></div>
                </div>
                <div className="schedule-container224">
                  <span className="schedule-text182">Regular Clean</span>
                  <div className="schedule-container225"></div>
                </div>
                <div className="schedule-container226">
                  <span className="schedule-text183">Vacate Clean</span>
                  <div className="schedule-container227"></div>
                </div>
                <div className="schedule-container228">
                  <span className="schedule-text184">Book Now</span>
                </div>
              </div>
              <div className="schedule-container229">
                <div className="schedule-container230">
                  <span className="schedule-text185">9</span>
                  <div className="schedule-container231">
                    <span className="schedule-text186">1</span>
                  </div>
                  <div className="schedule-container232">
                    <span className="schedule-text187">1</span>
                  </div>
                  <div className="schedule-container233">
                    <span className="schedule-text188">1</span>
                  </div>
                </div>
                <div className="schedule-container234">
                  <span className="schedule-text189">Deep Clean</span>
                  <div className="schedule-container235"></div>
                </div>
                <div className="schedule-container236">
                  <span className="schedule-text190">Regular Clean</span>
                  <div className="schedule-container237"></div>
                </div>
                <div className="schedule-container238">
                  <span className="schedule-text191">Vacate Clean</span>
                  <div className="schedule-container239"></div>
                </div>
                <div className="schedule-container240">
                  <span className="schedule-text192">Book Now</span>
                </div>
              </div>
              <div className="schedule-container241">
                <div className="schedule-container242">
                  <span className="schedule-text193">10</span>
                  <div className="schedule-container243">
                    <span className="schedule-text194">1</span>
                  </div>
                  <div className="schedule-container244">
                    <span className="schedule-text195">1</span>
                  </div>
                  <div className="schedule-container245">
                    <span className="schedule-text196">1</span>
                  </div>
                </div>
                <div className="schedule-container246">
                  <span className="schedule-text197">Deep Clean</span>
                  <div className="schedule-container247"></div>
                </div>
                <div className="schedule-container248">
                  <span className="schedule-text198">Regular Clean</span>
                  <div className="schedule-container249"></div>
                </div>
                <div className="schedule-container250">
                  <span className="schedule-text199">Vacate Clean</span>
                  <div className="schedule-container251"></div>
                </div>
                <div className="schedule-container252">
                  <span className="schedule-text200">Book Now</span>
                </div>
              </div>
              <div className="schedule-container253">
                <div className="schedule-container254">
                  <span className="schedule-text201">11</span>
                  <div className="schedule-container255">
                    <span className="schedule-text202">1</span>
                  </div>
                  <div className="schedule-container256">
                    <span className="schedule-text203">1</span>
                  </div>
                  <div className="schedule-container257">
                    <span className="schedule-text204">1</span>
                  </div>
                </div>
                <div className="schedule-container258">
                  <span className="schedule-text205">Deep Clean</span>
                  <div className="schedule-container259"></div>
                </div>
                <div className="schedule-container260">
                  <span className="schedule-text206">Regular Clean</span>
                  <div className="schedule-container261"></div>
                </div>
                <div className="schedule-container262">
                  <span className="schedule-text207">Vacate Clean</span>
                  <div className="schedule-container263"></div>
                </div>
                <div className="schedule-container264">
                  <span className="schedule-text208">Book Now</span>
                </div>
              </div>
              <div className="schedule-container265">
                <div className="schedule-container266">
                  <span className="schedule-text209">12</span>
                  <div className="schedule-container267">
                    <span className="schedule-text210">1</span>
                  </div>
                  <div className="schedule-container268">
                    <span className="schedule-text211">1</span>
                  </div>
                  <div className="schedule-container269">
                    <span className="schedule-text212">12</span>
                  </div>
                </div>
                <div className="schedule-container270">
                  <span className="schedule-text213">Deep Clean</span>
                  <div className="schedule-container271"></div>
                </div>
                <div className="schedule-container272">
                  <span className="schedule-text214">Regular Clean</span>
                  <div className="schedule-container273"></div>
                </div>
                <div className="schedule-container274">
                  <span className="schedule-text215">Vacate Clean</span>
                  <div className="schedule-container275"></div>
                </div>
                <div className="schedule-container276">
                  <span className="schedule-text216">Book Now</span>
                </div>
              </div>
              <div className="schedule-container277">
                <div className="schedule-container278">
                  <span className="schedule-text217">13</span>
                  <div className="schedule-container279">
                    <span className="schedule-text218">1</span>
                  </div>
                  <div className="schedule-container280">
                    <span className="schedule-text219">1</span>
                  </div>
                  <div className="schedule-container281">
                    <span className="schedule-text220">1</span>
                  </div>
                </div>
                <div className="schedule-container282">
                  <span className="schedule-text221">Deep Clean</span>
                  <div className="schedule-container283"></div>
                </div>
                <div className="schedule-container284">
                  <span className="schedule-text222">Regular Clean</span>
                  <div className="schedule-container285"></div>
                </div>
                <div className="schedule-container286">
                  <span className="schedule-text223">Vacate Clean</span>
                  <div className="schedule-container287"></div>
                </div>
                <div className="schedule-container288">
                  <span className="schedule-text224">Book Now</span>
                </div>
              </div>
              <div className="schedule-container289">
                <div className="schedule-container290">
                  <span className="schedule-text225">14</span>
                  <div className="schedule-container291">
                    <span className="schedule-text226">1</span>
                  </div>
                  <div className="schedule-container292">
                    <span className="schedule-text227">1</span>
                  </div>
                  <div className="schedule-container293">
                    <span className="schedule-text228">1</span>
                  </div>
                </div>
                <div className="schedule-container294">
                  <span className="schedule-text229">Deep Clean</span>
                  <div className="schedule-container295"></div>
                </div>
                <div className="schedule-container296">
                  <span className="schedule-text230">Regular Clean</span>
                  <div className="schedule-container297"></div>
                </div>
                <div className="schedule-container298">
                  <span className="schedule-text231">Vacate Clean</span>
                  <div className="schedule-container299"></div>
                </div>
                <div className="schedule-container300">
                  <span className="schedule-text232">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule-container301">
              <div className="schedule-container302">
                <div className="schedule-container303">
                  <span className="schedule-text233">15</span>
                  <div className="schedule-container304">
                    <span className="schedule-text234">1</span>
                  </div>
                  <div className="schedule-container305">
                    <span className="schedule-text235">1</span>
                  </div>
                  <div className="schedule-container306">
                    <span className="schedule-text236">1</span>
                  </div>
                </div>
                <div className="schedule-container307">
                  <span className="schedule-text237">Deep Clean</span>
                  <div className="schedule-container308"></div>
                </div>
                <div className="schedule-container309">
                  <span className="schedule-text238">Regular Clean</span>
                  <div className="schedule-container310"></div>
                </div>
                <div className="schedule-container311">
                  <span className="schedule-text239">Vacate Clean</span>
                  <div className="schedule-container312"></div>
                </div>
                <div className="schedule-container313">
                  <span className="schedule-text240">Book Now</span>
                </div>
              </div>
              <div className="schedule-container314">
                <div className="schedule-container315">
                  <span className="schedule-text241">16</span>
                  <div className="schedule-container316">
                    <span className="schedule-text242">1</span>
                  </div>
                  <div className="schedule-container317">
                    <span className="schedule-text243">1</span>
                  </div>
                  <div className="schedule-container318">
                    <span className="schedule-text244">1</span>
                  </div>
                </div>
                <div className="schedule-container319">
                  <span className="schedule-text245">Deep Clean</span>
                  <div className="schedule-container320"></div>
                </div>
                <div className="schedule-container321">
                  <span className="schedule-text246">Regular Clean</span>
                  <div className="schedule-container322"></div>
                </div>
                <div className="schedule-container323">
                  <span className="schedule-text247">Vacate Clean</span>
                  <div className="schedule-container324"></div>
                </div>
                <div className="schedule-container325">
                  <span className="schedule-text248">Book Now</span>
                </div>
              </div>
              <div className="schedule-container326">
                <div className="schedule-container327">
                  <span className="schedule-text249">17</span>
                  <div className="schedule-container328">
                    <span className="schedule-text250">1</span>
                  </div>
                  <div className="schedule-container329">
                    <span className="schedule-text251">1</span>
                  </div>
                  <div className="schedule-container330">
                    <span className="schedule-text252">1</span>
                  </div>
                </div>
                <div className="schedule-container331">
                  <span className="schedule-text253">Deep Clean</span>
                  <div className="schedule-container332"></div>
                </div>
                <div className="schedule-container333">
                  <span className="schedule-text254">Regular Clean</span>
                  <div className="schedule-container334"></div>
                </div>
                <div className="schedule-container335">
                  <span className="schedule-text255">Vacate Clean</span>
                  <div className="schedule-container336"></div>
                </div>
                <div className="schedule-container337">
                  <span className="schedule-text256">Book Now</span>
                </div>
              </div>
              <div className="schedule-container338">
                <div className="schedule-container339">
                  <span className="schedule-text257">18</span>
                  <div className="schedule-container340">
                    <span className="schedule-text258">1</span>
                  </div>
                  <div className="schedule-container341">
                    <span className="schedule-text259">1</span>
                  </div>
                  <div className="schedule-container342">
                    <span className="schedule-text260">1</span>
                  </div>
                </div>
                <div className="schedule-container343">
                  <span className="schedule-text261">Deep Clean</span>
                  <div className="schedule-container344"></div>
                </div>
                <div className="schedule-container345">
                  <span className="schedule-text262">Regular Clean</span>
                  <div className="schedule-container346"></div>
                </div>
                <div className="schedule-container347">
                  <span className="schedule-text263">Vacate Clean</span>
                  <div className="schedule-container348"></div>
                </div>
                <div className="schedule-container349">
                  <span className="schedule-text264">Book Now</span>
                </div>
              </div>
              <div className="schedule-container350">
                <div className="schedule-container351">
                  <span className="schedule-text265">19</span>
                  <div className="schedule-container352">
                    <span className="schedule-text266">1</span>
                  </div>
                  <div className="schedule-container353">
                    <span className="schedule-text267">1</span>
                  </div>
                  <div className="schedule-container354">
                    <span className="schedule-text268">1</span>
                  </div>
                </div>
                <div className="schedule-container355">
                  <span className="schedule-text269">Deep Clean</span>
                  <div className="schedule-container356"></div>
                </div>
                <div className="schedule-container357">
                  <span className="schedule-text270">Regular Clean</span>
                  <div className="schedule-container358"></div>
                </div>
                <div className="schedule-container359">
                  <span className="schedule-text271">Vacate Clean</span>
                  <div className="schedule-container360"></div>
                </div>
                <div className="schedule-container361">
                  <span className="schedule-text272">Book Now</span>
                </div>
              </div>
              <div className="schedule-container362">
                <div className="schedule-container363">
                  <span className="schedule-text273">20</span>
                  <div className="schedule-container364">
                    <span className="schedule-text274">1</span>
                  </div>
                  <div className="schedule-container365">
                    <span className="schedule-text275">1</span>
                  </div>
                  <div className="schedule-container366">
                    <span className="schedule-text276">1</span>
                  </div>
                </div>
                <div className="schedule-container367">
                  <span className="schedule-text277">Deep Clean</span>
                  <div className="schedule-container368"></div>
                </div>
                <div className="schedule-container369">
                  <span className="schedule-text278">Regular Clean</span>
                  <div className="schedule-container370"></div>
                </div>
                <div className="schedule-container371">
                  <span className="schedule-text279">Vacate Clean</span>
                  <div className="schedule-container372"></div>
                </div>
                <div className="schedule-container373">
                  <span className="schedule-text280">Book Now</span>
                </div>
              </div>
              <div className="schedule-container374">
                <div className="schedule-container375">
                  <span className="schedule-text281">21</span>
                  <div className="schedule-container376">
                    <span className="schedule-text282">1</span>
                  </div>
                  <div className="schedule-container377">
                    <span className="schedule-text283">1</span>
                  </div>
                  <div className="schedule-container378">
                    <span className="schedule-text284">1</span>
                  </div>
                </div>
                <div className="schedule-container379">
                  <span className="schedule-text285">Deep Clean</span>
                  <div className="schedule-container380"></div>
                </div>
                <div className="schedule-container381">
                  <span className="schedule-text286">Regular Clean</span>
                  <div className="schedule-container382"></div>
                </div>
                <div className="schedule-container383">
                  <span className="schedule-text287">Vacate Clean</span>
                  <div className="schedule-container384"></div>
                </div>
                <div className="schedule-container385">
                  <span className="schedule-text288">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule-container386">
              <div className="schedule-container387">
                <div className="schedule-container388">
                  <span className="schedule-text289">22</span>
                  <div className="schedule-container389">
                    <span className="schedule-text290">1</span>
                  </div>
                  <div className="schedule-container390">
                    <span className="schedule-text291">1</span>
                  </div>
                  <div className="schedule-container391">
                    <span className="schedule-text292">1</span>
                  </div>
                </div>
                <div className="schedule-container392">
                  <span className="schedule-text293">Deep Clean</span>
                  <div className="schedule-container393"></div>
                </div>
                <div className="schedule-container394">
                  <span className="schedule-text294">Regular Clean</span>
                  <div className="schedule-container395"></div>
                </div>
                <div className="schedule-container396">
                  <span className="schedule-text295">Vacate Clean</span>
                  <div className="schedule-container397"></div>
                </div>
                <div className="schedule-container398">
                  <span className="schedule-text296">Book Now</span>
                </div>
              </div>
              <div className="schedule-container399">
                <div className="schedule-container400">
                  <span className="schedule-text297">23</span>
                  <div className="schedule-container401">
                    <span className="schedule-text298">1</span>
                  </div>
                  <div className="schedule-container402">
                    <span className="schedule-text299">1</span>
                  </div>
                  <div className="schedule-container403">
                    <span className="schedule-text300">1</span>
                  </div>
                </div>
                <div className="schedule-container404">
                  <span className="schedule-text301">Deep Clean</span>
                  <div className="schedule-container405"></div>
                </div>
                <div className="schedule-container406">
                  <span className="schedule-text302">Regular Clean</span>
                  <div className="schedule-container407"></div>
                </div>
                <div className="schedule-container408">
                  <span className="schedule-text303">Vacate Clean</span>
                  <div className="schedule-container409"></div>
                </div>
                <div className="schedule-container410">
                  <span className="schedule-text304">Book Now</span>
                </div>
              </div>
              <div className="schedule-container411">
                <div className="schedule-container412">
                  <span className="schedule-text305">24</span>
                  <div className="schedule-container413">
                    <span className="schedule-text306">1</span>
                  </div>
                  <div className="schedule-container414">
                    <span className="schedule-text307">1</span>
                  </div>
                  <div className="schedule-container415">
                    <span className="schedule-text308">1</span>
                  </div>
                </div>
                <div className="schedule-container416">
                  <span className="schedule-text309">Deep Clean</span>
                  <div className="schedule-container417"></div>
                </div>
                <div className="schedule-container418">
                  <span className="schedule-text310">Regular Clean</span>
                  <div className="schedule-container419"></div>
                </div>
                <div className="schedule-container420">
                  <span className="schedule-text311">Vacate Clean</span>
                  <div className="schedule-container421"></div>
                </div>
                <div className="schedule-container422">
                  <span className="schedule-text312">Book Now</span>
                </div>
              </div>
              <div className="schedule-container423">
                <div className="schedule-container424">
                  <span className="schedule-text313">25</span>
                  <div className="schedule-container425">
                    <span className="schedule-text314">1</span>
                  </div>
                  <div className="schedule-container426">
                    <span className="schedule-text315">1</span>
                  </div>
                  <div className="schedule-container427">
                    <span className="schedule-text316">1</span>
                  </div>
                </div>
                <div className="schedule-container428">
                  <span className="schedule-text317">Deep Clean</span>
                  <div className="schedule-container429"></div>
                </div>
                <div className="schedule-container430">
                  <span className="schedule-text318">Regular Clean</span>
                  <div className="schedule-container431"></div>
                </div>
                <div className="schedule-container432">
                  <span className="schedule-text319">Vacate Clean</span>
                  <div className="schedule-container433"></div>
                </div>
                <div className="schedule-container434">
                  <span className="schedule-text320">Book Now</span>
                </div>
              </div>
              <div className="schedule-container435">
                <div className="schedule-container436">
                  <span className="schedule-text321">26</span>
                  <div className="schedule-container437">
                    <span className="schedule-text322">1</span>
                  </div>
                  <div className="schedule-container438">
                    <span className="schedule-text323">1</span>
                  </div>
                  <div className="schedule-container439">
                    <span className="schedule-text324">1</span>
                  </div>
                </div>
                <div className="schedule-container440">
                  <span className="schedule-text325">Deep Clean</span>
                  <div className="schedule-container441"></div>
                </div>
                <div className="schedule-container442">
                  <span className="schedule-text326">Regular Clean</span>
                  <div className="schedule-container443"></div>
                </div>
                <div className="schedule-container444">
                  <span className="schedule-text327">Vacate Clean</span>
                  <div className="schedule-container445"></div>
                </div>
                <div className="schedule-container446">
                  <span className="schedule-text328">Book Now</span>
                </div>
              </div>
              <div className="schedule-container447">
                <div className="schedule-container448">
                  <span className="schedule-text329">27</span>
                  <div className="schedule-container449">
                    <span className="schedule-text330">1</span>
                  </div>
                  <div className="schedule-container450">
                    <span className="schedule-text331">1</span>
                  </div>
                  <div className="schedule-container451">
                    <span className="schedule-text332">1</span>
                  </div>
                </div>
                <div className="schedule-container452">
                  <span className="schedule-text333">Deep Clean</span>
                  <div className="schedule-container453"></div>
                </div>
                <div className="schedule-container454">
                  <span className="schedule-text334">Regular Clean</span>
                  <div className="schedule-container455"></div>
                </div>
                <div className="schedule-container456">
                  <span className="schedule-text335">Vacate Clean</span>
                  <div className="schedule-container457"></div>
                </div>
                <div className="schedule-container458">
                  <span className="schedule-text336">Book Now</span>
                </div>
              </div>
              <div className="schedule-container459">
                <div className="schedule-container460">
                  <span className="schedule-text337">28</span>
                  <div className="schedule-container461">
                    <span className="schedule-text338">1</span>
                  </div>
                  <div className="schedule-container462">
                    <span className="schedule-text339">1</span>
                  </div>
                  <div className="schedule-container463">
                    <span className="schedule-text340">1</span>
                  </div>
                </div>
                <div className="schedule-container464">
                  <span className="schedule-text341">Deep Clean</span>
                  <div className="schedule-container465"></div>
                </div>
                <div className="schedule-container466">
                  <span className="schedule-text342">Regular Clean</span>
                  <div className="schedule-container467"></div>
                </div>
                <div className="schedule-container468">
                  <span className="schedule-text343">Vacate Clean</span>
                  <div className="schedule-container469"></div>
                </div>
                <div className="schedule-container470">
                  <span className="schedule-text344">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule-container471">
              <div className="schedule-container472">
                <div className="schedule-container473">
                  <span className="schedule-text345">29</span>
                  <div className="schedule-container474">
                    <span className="schedule-text346">1</span>
                  </div>
                  <div className="schedule-container475">
                    <span className="schedule-text347">1</span>
                  </div>
                  <div className="schedule-container476">
                    <span className="schedule-text348">1</span>
                  </div>
                </div>
                <div className="schedule-container477">
                  <span className="schedule-text349">Deep Clean</span>
                  <div className="schedule-container478"></div>
                </div>
                <div className="schedule-container479">
                  <span className="schedule-text350">Regular Clean</span>
                  <div className="schedule-container480"></div>
                </div>
                <div className="schedule-container481">
                  <span className="schedule-text351">Vacate Clean</span>
                  <div className="schedule-container482"></div>
                </div>
                <div className="schedule-container483">
                  <span className="schedule-text352">Book Now</span>
                </div>
              </div>
              <div className="schedule-container484">
                <div className="schedule-container485">
                  <span className="schedule-text353">30</span>
                  <div className="schedule-container486">
                    <span className="schedule-text354">1</span>
                  </div>
                  <div className="schedule-container487">
                    <span className="schedule-text355">1</span>
                  </div>
                  <div className="schedule-container488">
                    <span className="schedule-text356">1</span>
                  </div>
                </div>
                <div className="schedule-container489">
                  <span className="schedule-text357">Deep Clean</span>
                  <div className="schedule-container490"></div>
                </div>
                <div className="schedule-container491">
                  <span className="schedule-text358">Regular Clean</span>
                  <div className="schedule-container492"></div>
                </div>
                <div className="schedule-container493">
                  <span className="schedule-text359">Vacate Clean</span>
                  <div className="schedule-container494"></div>
                </div>
                <div className="schedule-container495">
                  <span className="schedule-text360">Book Now</span>
                </div>
              </div>
              <div className="schedule-container496">
                <div className="schedule-container497">
                  <span className="schedule-text361">31</span>
                  <div className="schedule-container498">
                    <span className="schedule-text362">1</span>
                  </div>
                  <div className="schedule-container499">
                    <span className="schedule-text363">1</span>
                  </div>
                  <div className="schedule-container500">
                    <span className="schedule-text364">1</span>
                  </div>
                </div>
                <div className="schedule-container501">
                  <span className="schedule-text365">Deep Clean</span>
                  <div className="schedule-container502"></div>
                </div>
                <div className="schedule-container503">
                  <span className="schedule-text366">Regular Clean</span>
                  <div className="schedule-container504"></div>
                </div>
                <div className="schedule-container505">
                  <span className="schedule-text367">Vacate Clean</span>
                  <div className="schedule-container506"></div>
                </div>
                <div className="schedule-container507">
                  <span className="schedule-text368">Book Now</span>
                </div>
              </div>
              <div className="schedule-container508">
                <div className="schedule-container509">
                  <span className="schedule-text369">1</span>
                  <div className="schedule-container510">
                    <span className="schedule-text370">1</span>
                  </div>
                  <div className="schedule-container511">
                    <span className="schedule-text371">1</span>
                  </div>
                  <div className="schedule-container512">
                    <span className="schedule-text372">1</span>
                  </div>
                </div>
                <div className="schedule-container513">
                  <span className="schedule-text373">Deep Clean</span>
                  <div className="schedule-container514"></div>
                </div>
                <div className="schedule-container515">
                  <span className="schedule-text374">Regular Clean</span>
                  <div className="schedule-container516"></div>
                </div>
                <div className="schedule-container517">
                  <span className="schedule-text375">Vacate Clean</span>
                  <div className="schedule-container518"></div>
                </div>
                <div className="schedule-container519">
                  <span className="schedule-text376">Book Now</span>
                </div>
              </div>
              <div className="schedule-container520">
                <div className="schedule-container521">
                  <span className="schedule-text377">2</span>
                  <div className="schedule-container522">
                    <span className="schedule-text378">1</span>
                  </div>
                  <div className="schedule-container523">
                    <span className="schedule-text379">1</span>
                  </div>
                  <div className="schedule-container524">
                    <span className="schedule-text380">1</span>
                  </div>
                </div>
                <div className="schedule-container525">
                  <span className="schedule-text381">Deep Clean</span>
                  <div className="schedule-container526"></div>
                </div>
                <div className="schedule-container527">
                  <span className="schedule-text382">Regular Clean</span>
                  <div className="schedule-container528"></div>
                </div>
                <div className="schedule-container529">
                  <span className="schedule-text383">Vacate Clean</span>
                  <div className="schedule-container530"></div>
                </div>
                <div className="schedule-container531">
                  <span className="schedule-text384">Book Now</span>
                </div>
              </div>
              <div className="schedule-container532">
                <div className="schedule-container533">
                  <span className="schedule-text385">3</span>
                  <div className="schedule-container534">
                    <span className="schedule-text386">1</span>
                  </div>
                  <div className="schedule-container535">
                    <span className="schedule-text387">1</span>
                  </div>
                  <div className="schedule-container536">
                    <span className="schedule-text388">1</span>
                  </div>
                </div>
                <div className="schedule-container537">
                  <span className="schedule-text389">Deep Clean</span>
                  <div className="schedule-container538"></div>
                </div>
                <div className="schedule-container539">
                  <span className="schedule-text390">Regular Clean</span>
                  <div className="schedule-container540"></div>
                </div>
                <div className="schedule-container541">
                  <span className="schedule-text391">Vacate Clean</span>
                  <div className="schedule-container542"></div>
                </div>
                <div className="schedule-container543">
                  <span className="schedule-text392">Book Now</span>
                </div>
              </div>
              <div className="schedule-container544">
                <div className="schedule-container545">
                  <span className="schedule-text393">4</span>
                  <div className="schedule-container546">
                    <span className="schedule-text394">1</span>
                  </div>
                  <div className="schedule-container547">
                    <span className="schedule-text395">1</span>
                  </div>
                  <div className="schedule-container548">
                    <span className="schedule-text396">1</span>
                  </div>
                </div>
                <div className="schedule-container549">
                  <span className="schedule-text397">Deep Clean</span>
                  <div className="schedule-container550"></div>
                </div>
                <div className="schedule-container551">
                  <span className="schedule-text398">Regular Clean</span>
                  <div className="schedule-container552"></div>
                </div>
                <div className="schedule-container553">
                  <span className="schedule-text399">Vacate Clean</span>
                  <div className="schedule-container554"></div>
                </div>
                <div className="schedule-container555">
                  <span className="schedule-text400">Book Now</span>
                </div>
              </div>
            </div>
            <div className="schedule-container556">
              <div className="schedule-container557">
                <div className="schedule-container558">
                  <div className="schedule-container559">
                    <img
                      alt="image"
                      src={require("./img/calenderx-200h.png")}
                      className="schedule-image22"
                    />
                    <span className="schedule-text401">1st January, 2024</span>
                  </div>
                  <div className="schedule-container560">
                    <div className="schedule-container561">
                      <div className="schedule-container562"></div>
                    </div>
                    <div className="schedule-container563">
                      <span className="schedule-text402">
                        Deep Clean for apartment
                      </span>
                      <span className="schedule-text403">
                        Completed Jan 20th
                      </span>
                      <div className="schedule-container564">
                        <span className="schedule-text404">1X Bathroom</span>
                        <span className="schedule-text405">5X Kitchen</span>
                        <span className="schedule-text406">7X Bedroom</span>
                        <span className="schedule-text407">1X Microwave</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="schedule-container565">
                  <div className="schedule-container566">
                    <span className="schedule-text408">Reschedule</span>
                  </div>
                  <div className="schedule-container567">
                    <span className="schedule-text409">Cancel</span>
                  </div>
                </div>
              </div>
              <div className="schedule-container568">
                <div className="schedule-container569">
                  <div className="schedule-container570">
                    <img
                      alt="image"
                      src={require("./img/calenderx-200h.png")}
                      className="schedule-image23"
                    />
                    <span className="schedule-text410">12th January, 2024</span>
                  </div>
                  <div className="schedule-container571">
                    <div className="schedule-container572">
                      <div className="schedule-container573"></div>
                    </div>
                    <div className="schedule-container574">
                      <span className="schedule-text411">
                        Deep Clean for apartment
                      </span>
                      <span className="schedule-text412">
                        Completed Jan 20th
                      </span>
                      <div className="schedule-container575">
                        <span className="schedule-text413">1X Bathroom</span>
                        <span className="schedule-text414">5X Kitchen</span>
                        <span className="schedule-text415">7X Bedroom</span>
                        <span className="schedule-text416">1X Microwave</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="schedule-container576">
                  <div className="schedule-container577">
                    <span className="schedule-text417">Reschedule</span>
                  </div>
                  <div className="schedule-container578">
                    <span className="schedule-text418">Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Schedule
