import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './reward.css'

const Reward = (props) => {
  return (
    <div className="reward-container10">
      <Helmet>
        <title>reward - Crips Cleaning</title>
        <meta
          name="description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
        <meta property="og:title" content="reward - Crips Cleaning" />
        <meta
          property="og:description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
      </Helmet>
      <div className="reward-container11">
        <img alt="image" src={require("./img/logo-200h.png")} className="reward-image10" />
        <div className="reward-container12">
          <span className="reward-text10">OVERVIEW</span>
          <Link to="/dashboard" className="reward-navlink1">
            <div className="reward-container13">
              <img
                alt="image"
                src={require("./img/homep-200h.png")}
                className="reward-image11"
              />
              <span className="reward-text11">Dashboard</span>
            </div>
          </Link>
          <Link to="/schedule" className="reward-navlink2">
            <div className="reward-container14">
              <img
                alt="image"
                src={require("./img/calenderx-200h.png")}
                className="reward-image12"
              />
              <span className="reward-text12">Schedule</span>
            </div>
          </Link>
          <Link to="/referral" className="reward-navlink3">
            <div className="reward-container15">
              <img
                alt="image"
                src={require("./img/link-200h.png")}
                className="reward-image13"
              />
              <span className="reward-text13">Referrals</span>
            </div>
          </Link>
          <div className="reward-container16">
            <img
              alt="image"
              src={require("./img/lock1-200h.png")}
              className="reward-image14"
            />
            <span className="reward-text14">Rewards</span>
          </div>
          <Link to="/cleanerspass" className="reward-navlink4">
            <div className="reward-container17">
              <img
                alt="image"
                src={require("./img/key-200h.png")}
                className="reward-image15"
              />
              <span className="reward-text15">CleanPass</span>
            </div>
          </Link>
        </div>
        <div className="reward-container18">
          <span className="reward-text16">SETTINGS</span>
          <Link to="/settings" className="reward-navlink5">
            <div className="reward-container19">
              <img
                alt="image"
                src={require("./img/settings_x-200h.png")}
                className="reward-image16"
              />
              <span className="reward-text17">Settings</span>
            </div>
          </Link>
          <div className="reward-container20">
            <img
              alt="image"
              src={require("./img/exitx-200h.png")}
              className="reward-image17"
            />
            <span className="reward-text18">Logout</span>
          </div>
        </div>
      </div>
      <div className="reward-container21">
        <div className="reward-container22">
          <span className="reward-text19">Rewards</span>
          <img
            alt="image"
            src={require("./img/question-200h.png")}
            className="reward-image18"
          />
          <div className="reward-container23">
            <img
              alt="image"
              src={require("./img/search-200h.png")}
              className="reward-image19"
            />
            <span className="reward-text20">Search for anything...</span>
            <input type="text" className="reward-textinput input" />
          </div>
          <div className="reward-container24">
            <span className="reward-text21">Book Now</span>
          </div>
        </div>
        <div className="reward-container25">
          <div className="reward-container26">
            <div className="reward-container27">
              <span className="reward-text22">Challenges</span>
              <span className="reward-text23">
                Complete challenges for lifetime rewards
              </span>
              <div className="reward-container28">
                <div className="reward-container29">
                  <span className="reward-text24">Your Progress</span>
                  <span className="reward-text25">View challenges</span>
                </div>
                <div className="reward-container30">
                  <div className="reward-container31">
                    <img
                      alt="image"
                      src={require("./img/left-200w.png")}
                      className="reward-image20"
                    />
                  </div>
                  <div className="reward-container32">
                    <div className="reward-container33">
                      <div className="reward-container34"></div>
                    </div>
                    <div className="reward-container35">
                      <span className="reward-text26">1</span>
                    </div>
                    <div className="reward-container36">
                      <span className="reward-text27">
                        <span>2</span>
                        <br></br>
                      </span>
                    </div>
                    <div className="reward-container37">
                      <span className="reward-text30">3</span>
                    </div>
                    <div className="reward-container38">
                      <span className="reward-text31">4</span>
                    </div>
                  </div>
                  <div className="reward-container39">
                    <img
                      alt="image"
                      src={require("./img/right-200w.png")}
                      className="reward-image21"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="reward-container40">
              <div className="reward-container41">
                <div className="reward-container42">
                  <img
                    alt="image"
                    src={require("./img/lock-200w.png")}
                    className="reward-image22"
                  />
                  <span className="reward-text32">To be completed</span>
                </div>
                <div className="reward-container43">
                  <div className="reward-container44">
                    <span className="reward-text33">Refferals</span>
                    <span className="reward-text34">3 of 4</span>
                  </div>
                  <div className="reward-container45">
                    <div className="reward-container46"></div>
                  </div>
                </div>
                <div className="reward-container47">
                  <div className="reward-container48">
                    <span className="reward-text35">Cleans</span>
                    <span className="reward-text36">3 of 3</span>
                  </div>
                  <div className="reward-container49">
                    <div className="reward-container50"></div>
                  </div>
                </div>
                <div className="reward-container51">
                  <div className="reward-container52">
                    <span className="reward-text37">Miscellaneous</span>
                    <span className="reward-text38">1 of 3</span>
                  </div>
                  <div className="reward-container53">
                    <div className="reward-container54"></div>
                  </div>
                </div>
                <div className="reward-container55">
                  <span className="reward-text39">View challenges</span>
                </div>
              </div>
              <div className="reward-container56">
                <div className="reward-container57">
                  <div className="reward-container58">
                    <img
                      alt="image"
                      src={require("./img/reward-200w.png")}
                      className="reward-image23"
                    />
                    <span className="reward-text40">Rewards</span>
                  </div>
                  <div className="reward-container59">
                    <div className="reward-container60"></div>
                    <div className="reward-container61">
                      <span className="reward-text41">
                        <span className="reward-text42">
                          Next 3 cleans for FREE
                        </span>
                        <br className="reward-text43"></br>
                        <span className="reward-text44">
                          Complete 1 challenge
                        </span>
                      </span>
                    </div>
                    <div className="reward-container62">
                      <span className="reward-text45">Claim reward</span>
                    </div>
                  </div>
                  <div className="reward-container63">
                    <div className="reward-container64"></div>
                    <div className="reward-container65">
                      <span className="reward-text46">
                        <span className="reward-text47">
                          Lifetime discount!
                        </span>
                        <br className="reward-text48"></br>
                        <span>Complete 3 challenges.</span>
                      </span>
                    </div>
                    <div className="reward-container66">
                      <span className="reward-text50">Finish task</span>
                    </div>
                  </div>
                  <div className="reward-container67">
                    <div className="reward-container68">
                      <img
                        alt="image"
                        src={require("./img/left-200w.png")}
                        className="reward-image24"
                      />
                    </div>
                    <div className="reward-container69">
                      <img
                        alt="image"
                        src={require("./img/right-200w.png")}
                        className="reward-image25"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reward
