import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './settings.css'

const Settings = (props) => {
  return (
    <div className="settings-container10">
      <Helmet>
        <title>settings - Crips Cleaning</title>
        <meta
          name="description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
        <meta property="og:title" content="settings - Crips Cleaning" />
        <meta
          property="og:description"
          content="We bring out the beauty in your environment. Eliminating every dirt and stains in your residence"
        />
      </Helmet>
      <div className="settings-container11">
        <img
          alt="image"
          src={require("./img/logo-200h.png")}
          className="settings-image10"
        />
        <div className="settings-container12">
          <span className="settings-text10">OVERVIEW</span>
          <Link to="/dashboard" className="settings-navlink1">
            <div className="settings-container13">
              <img
                alt="image"
                src={require("./img/homep-200h.png")}
                className="settings-image11"
              />
              <span className="settings-text11">Dashboard</span>
            </div>
          </Link>
          <span className="settings-text12">SETTINGS</span>
          <div className="settings-container14">
            <img
              alt="image"
              src={require("./img/homep-200h.png")}
              className="settings-image12"
            />
            <span className="settings-text13">Profile</span>
          </div>
          <Link to="/transaction" className="settings-navlink2">
            <div className="settings-container15">
              <img
                alt="image"
                src={require("./img/calenderx-300w.png")}
                className="settings-image13"
              />
              <span className="settings-text14">Transactions</span>
            </div>
          </Link>
          <div className="settings-container16">
            <img
              alt="image"
              src={require("./img/key-200h.png")}
              className="settings-image14"
            />
            <div className="settings-container17">
              <span className="settings-text15">Support</span>
              <div className="settings-container18">
                <span className="settings-text16">Contact us</span>
                <span className="settings-text17">FAQs</span>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-container19">
          <span className="settings-text18">SETTINGS</span>
          <div className="settings-container20">
            <img
              alt="image"
              src={require("./img/settings_x-200h.png")}
              className="settings-image15"
            />
            <span className="settings-text19">Settings</span>
          </div>
          <div className="settings-container21">
            <img
              alt="image"
              src={require("./img/exitx-200h.png")}
              className="settings-image16"
            />
            <span className="settings-text20">Logout</span>
          </div>
        </div>
      </div>
      <div className="settings-container22">
        <div className="settings-container23">
          <span className="settings-text21">Settings</span>
          <div className="settings-container24">
            <img
              alt="image"
              src={require("./img/question-200h.png")}
              className="settings-image17"
            />
            <div className="settings-container25">
              <img
                alt="image"
                src={require("./img/search-200h.png")}
                className="settings-image18"
              />
              <span className="settings-text22">Search for anything...</span>
              <input type="text" className="settings-textinput1 input" />
            </div>
          </div>
          <Link to="/settings" className="settings-navlink3">
            <img
              alt="image"
              src={require("./img/setting-200h.png")}
              className="settings-image19"
            />
          </Link>
        </div>
        <div className="settings-container26">
          <span className="settings-text23">John Doe</span>
          <span className="settings-text24">
            Individual Account / Premium Account
          </span>
        </div>
        <div className="settings-container27">
          <div className="settings-container28">
            <span className="settings-text25">Personal Details</span>
            <div className="settings-container29">
              <div className="settings-container30">
                <div className="settings-container31">
                  <span className="settings-text26">Full name</span>
                  <input
                    type="text"
                    placeholder="John Doe"
                    className="settings-textinput2 input"
                  />
                </div>
                <div className="settings-container32">
                  <span className="settings-text27">Email Address</span>
                  <input
                    type="text"
                    placeholder="yourmail@mail.com"
                    className="settings-textinput3 input"
                  />
                </div>
              </div>
              <div className="settings-container33">
                <div className="settings-container34">
                  <span className="settings-text28">Mobile number</span>
                  <input
                    type="text"
                    placeholder="0423 18..."
                    className="settings-textinput4 input"
                  />
                </div>
                <div className="settings-container35">
                  <span className="settings-text29">Password</span>
                  <input
                    type="text"
                    placeholder="****"
                    className="settings-textinput5 input"
                  />
                </div>
              </div>
            </div>
            <div className="settings-container36">
              <div className="settings-container37">
                <span className="settings-text30">Address</span>
                <input
                  type="text"
                  placeholder="126 Church Hill Road, Melbourne, Victoria, 2816"
                  className="settings-textinput6 input"
                />
              </div>
              <div className="settings-container38">
                <div className="settings-container39"></div>
                <div className="settings-container40">
                  <div className="settings-container41">
                    <span className="settings-text31">Bathroom Amount</span>
                    <div className="settings-container42">
                      <span className="settings-text32">1</span>
                    </div>
                  </div>
                  <div className="settings-container43">
                    <span className="settings-text33">Kitchen Amount</span>
                    <div className="settings-container44">
                      <span className="settings-text34">1</span>
                    </div>
                  </div>
                  <div className="settings-container45">
                    <span className="settings-text35">Other Amount</span>
                    <div className="settings-container46">
                      <span className="settings-text36">1</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="settings-container47">
            <span className="settings-text37">Membership Details</span>
            <div className="settings-container48">
              <div className="settings-container49">
                <div className="settings-container50">
                  <div className="settings-container51">
                    <span className="settings-text38">Cleaner’s Pass</span>
                  </div>
                </div>
              </div>
              <div className="settings-container52">
                <div className="settings-container53">
                  <span className="settings-text39">Every:</span>
                  <select className="settings-select1">
                    <option value="Week">Week</option>
                    <option value="Fortnight">Fortnight</option>
                    <option value="Month">Month</option>
                  </select>
                </div>
                <div className="settings-container54">
                  <span className="settings-text40">on:</span>
                  <select className="settings-select2">
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                  </select>
                </div>
                <button type="button" className="settings-button1 button">
                  Reschedule
                </button>
                <button type="button" className="settings-button2 button">
                  Cancel Membership
                </button>
              </div>
              <div className="settings-container55">
                <div className="settings-container56">
                  <div className="settings-container57">
                    <span className="settings-text41">Cleaning Summary</span>
                    <img
                      alt="image"
                      src={require("./img/downarrow-200h.png")}
                      className="settings-image20"
                    />
                  </div>
                  <span className="settings-text42">Have a discount code?</span>
                </div>
                <span className="settings-text43">
                  <span>
                    Total
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="settings-text45">$172.99</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="settings-container58">
        <Link to="/dashboard">
          <div className="settings-container59">
            <img
              alt="image"
              src={require("./img/homep-200h.png")}
              className="settings-image21"
            />
            <span className="settings-text46">Dashboard</span>
          </div>
        </Link>
        <Link to="/schedule">
          <div className="settings-container60">
            <img
              alt="image"
              src={require("./img/calenderx-300w.png")}
              className="settings-image22"
            />
            <span className="settings-text47">Schedule</span>
          </div>
        </Link>
        <Link to="/cleanerspass">
          <div className="settings-container61">
            <img
              alt="image"
              src={require("./img/key-200h.png")}
              className="settings-image23"
            />
            <span className="settings-text48">Cleaners Pass</span>
          </div>
        </Link>
        <Link to="/reward">
          <div className="settings-container62">
            <img
              alt="image"
              src={require("./img/lock1-300w.png")}
              className="settings-image24"
            />
            <span className="settings-text49">Rewards</span>
          </div>
        </Link>
        <Link to="/referral">
          <div className="settings-container63">
            <img
              alt="image"
              src={require("./img/link-300w.png")}
              className="settings-image25"
            />
            <span className="settings-text50">Referrals</span>
          </div>
        </Link>
      </div>
      <div className="settings-container64"></div>
    </div>
  )
}

export default Settings
